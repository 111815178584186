import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import './view.css'
import axios from 'axios'
import { Backdrop, CircularProgress, DialogActions, DialogContent, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import MakePlayDialog from './dialogs/MakePlayDialog'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import RenamePlayDialog from './dialogs/RenamePlayDialog'
import DeletePlayDialog from './dialogs/DeletePlayDialog'
import DuplicatePlayDialog from './dialogs/DuplicatePlayDialog'
import EditPlayDialog from './dialogs/EditPlayDialog'
import { EditAttributes, PanoramaFishEye, PhotoCamera, Preview, Visibility } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Popover from '@mui/material/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import UploadPlayArtDialog from './dialogs/UploadPlayArtDialog'

const playUrl = '/v1/webapi/playbook_entries'
const playbookUrl = '/v1/webapi/playbook_schemes'
const jobUrl = '/v1/webapi/playeditor_jobs'
const signingUrl = 'https://5nbrj37ma8.execute-api.us-east-1.amazonaws.com/prod/sign'

export default function Plays (props) {
  const [selectedPlay, setSelectedPlay] = useState('')
  const [playbookData, setPlaybookData] = useState()
  const [playData, setPlayData] = useState([])
  const [jobData, setJobData] = useState([])
  const [editSelectedJobs, setEditSelectedJobs] = useState([])
  const [makeSelectedJobs, setMakeSelectedJobs] = useState([])
  const [players, setPlayers] = useState()

  const [makePlayDialog, setMakePlayDialog] = useState(false)
  const [editPlayDialog, setEditPlayDialog] = useState(false)
  const [editSelected, setEditSelected] = useState({})

  const [renamePlayDialog, setRenamePlayDialog] = useState(false)
  const [renameSelected, setRenameSelected] = useState({})

  const [uploadPlayArtDialog, setUploadPlayArtDialog] = useState(false)
  const [uploadPlayArtSelected, setUploadPlayArtSelected] = useState({})

  const [duplicatePlayDialog, setDuplicatePlayDialog] = useState(false)
  const [duplicateSelected, setDuplicateSelected] = useState({})
  const [deletePlayDialog, setDeletePlayDialog] = useState(false)
  const [deleteSelected, setDeleteSelected] = useState({})

  const [openAlert, setOpenAlert] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    axios.get(playUrl).then((response) => {
      setPlayData(response.data)
    }).catch(error => {
      console.log(error)
    })

    axios.get(playbookUrl).then((response) => {
      setPlaybookData(response.data)
    }).catch(error => {
      console.log(error)
    })

    axios.get(jobUrl).then((response) => {
      const formationsList = response.data.formation
      const jobsFromFormation = []

      for (let i = 0; i < formationsList.length; i++) {
        const formation = formationsList[i]
        if (formation.items) {
          for (let j = 0; j < formation.items.length; j++) {
            jobsFromFormation.push(formation.items[j])
          }
        }
      }

      setJobData(jobsFromFormation)
      // setTimeout(function () {
      //   setLoading(false)
      // }, 1000)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const updatePlayState = () => {
    axios
      .get(playUrl)
      .then((response) => {
        if (response.data.length === 0) {
          setPlayData(null)
        } else {
          setPlayData(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const uploadPlayArt = (play, file) => {
    const config = {
      headers: {
      },
      withCredentials: false
    }
    axios
      .get(signingUrl + '/' + play._id)
      .then((response) => {
        const uploadUrl = response.data

        axios
          .put(uploadUrl, file, config)
          .then((response) => {
            updatePlayState()

            const headers = new Headers()
            headers.append('pragma', 'no-cache')
            headers.append('cache-control', 'no-cache')

            const init = {
              method: 'GET',
              headers,
              mode: 'no-cors',
              cache: 'no-cache'
            }

            fetch(new Request('https://cdn.simwinsports.com/development/assets/plays/' + play._id + '.png'), init)
          })
          .catch((error) => {
            console.log(error)
          })

        // updatePlayState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const renamePlay = (play) => {
    axios
      .put(playUrl + '/' + play._id, play)
      .then((response) => {
        updatePlayState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const duplicatePlay = (play) => {
    axios
      .post(playUrl, play)
      .then((response) => {
        updatePlayState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removePlayFromPlaybook = (plays, playToDelete) => {
    let filtered = null
    if (plays?.find(entry => entry.playbookEntryIDs === playToDelete?._id)) {
      filtered = plays.filter(entry => entry.playbookEntryIDs !== selectedPlay._id)
    }
    return filtered
  }

  const deletePlay = (play) => {
    for (let i = 0; i < playbookData.length; i++) {
      const playbook = playbookData[i]
      let filtered = null

      switch (play.play_type) {
        case 'offense_play':
          filtered = removePlayFromPlaybook(playbook.offense, play)
          playbook.offense = filtered
          break
        case 'defense_play':
          filtered = removePlayFromPlaybook(playbook.defense, play)
          playbook.defense = filtered
          break
        case 'special_team_play':
          filtered = removePlayFromPlaybook(playbook.special, play)
          playbook.special = filtered
          break
      }

      if (filtered) {
        axios.put(playbookUrl + '/' + playbook._id, playbook).then((response) => {
          console.log('deleted play from playbook')
        }).catch(error => {
          console.log(error)
        })
      }
    }

    axios.delete(playUrl + '/' + deleteSelected._id).then((response) => {
      setPlayData(playData.filter(play => play._id !== selectedPlay._id))
    }).catch(error => {
      console.log(error)
    })

    updatePlayState()
  }

  const canSave = () => {
    let jobsAssigned = true

    if (!players) { jobsAssigned = false }

    players?.map((player) => {
      if (!player.jobAssigned) {
        jobsAssigned = false
      }
      return player
    })

    if (!jobsAssigned) {
      setOpenAlert(true)
      return false
    }

    return true
  }

  const savePlay = (play) => {
    if (!canSave()) { return }

    const jobs = [...players]
    const jobsToSave = []

    for (let i = 0; i < jobs.length; i++) {
      const possibleJobs = []

      for (let j = 0; j < jobs[i].jobs.length; j++) {
        possibleJobs.push({
          job: jobs[i].jobs[j].job
        })
      }
      const jobEntry = {}
      const top = jobs[i].position.top.split('%')
      const left = jobs[i].position.left.split('%')
      console.log(jobs[i])

      jobEntry.possibleJobs = possibleJobs
      jobEntry.position = jobs[i].value
      jobEntry.X = Number(top[0])
      jobEntry.Y = Number(left[0])
      // dict['size']=jobs[i].size
      jobsToSave.push(jobEntry)
    }

    const playbookEntry = {
      _id: play._id,
      background: play.background,
      play_value: play.play_value,
      play_type: play.play_type,
      formationId: play.formationId,
      formation_type: play.formation_type,
      formation_value: play.formation_value,
      IsMirrored: play.IsMirrored,
      jobs: jobsToSave,
      name: play.name
    }
    if (play._id) {
      axios
        .put(playUrl + '/' + play._id, playbookEntry)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      axios
        .post(playUrl, playbookEntry)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    setMakePlayDialog(false)
    setEditPlayDialog(false)
    updatePlayState()
  }

  const makePlay = (play) => {
    savePlay(play)
  }

  const editPlay = (play) => {
    savePlay(play)
  }

  const columns = [
    { field: '_id', headerName: 'ID', width: 300, hideable: true },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'play_type', headerName: 'Type', width: 200, hideable: true },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 600,
      getActions: (params) => {
        return [
          <UploadPlayArtButton key={0} play={params.row} handleClick={handleUploadPlayArtClick}/>,
          <ViewPlayArtButton key={1} play={params.row} handleClick={handleEditPlayClick}/>,
          <EditPlayButton key={2} play={params.row} handleClick={handleEditPlayClick}/>,
          <RenamePlayButton key={3} play={params.row} handleClick={handleRenamePlayClick}/>,
          <DuplicatePlayButton key={4} play={params.row} handleClick={handleDuplicatePlayClick}/>,
          <DeletePlayButton key={5} play={params.row} handleClick={handleDeletePlayClick}/>
        ]
      }
    }

  ]

  const matchJobsToPlay = (play) => {
    if (!play?.jobs) { return null }

    const jobResults = []

    for (let i = 0; i < play.jobs.length; i++) {
      const job = play.jobs[i]
      const possibleJobsList = job.possibleJobs
      const jobsForPosition = []

      for (let j = 0; j < possibleJobsList.length; j++) {
        const possibleJob = possibleJobsList[j]
        const jobName = possibleJob.job
        const jobMatch = jobData.find(jd => jd.value === jobName)

        if (jobMatch) {
          jobsForPosition.push({
            job: jobMatch.value,
            size: jobMatch.size,
            offsetX: jobMatch.offsetX,
            offsetY: jobMatch.offsetY,
            imgUrl: jobMatch.imageUrl
          })
        }
      }
      jobResults.push(jobsForPosition)
    }
    return jobResults
  }

  const handleMakePlayClick = () => {
    setMakeSelectedJobs([])
    setMakePlayDialog(true)
  }

  const handleEditPlayClick = (play) => {
    setEditSelected(play)
    setEditSelectedJobs(matchJobsToPlay(play))
    setEditPlayDialog(true)
  }

  const handleRenamePlayClick = (play) => {
    setRenameSelected(play)
    setRenamePlayDialog(true)
  }
  const handleDuplicatePlayClick = (play) => {
    setDuplicateSelected(play)
    setDuplicatePlayDialog(true)
  }

  const handleDeletePlayClick = (play) => {
    setDeleteSelected(play)
    setDeletePlayDialog(true)
  }

  const handleUploadPlayArtClick = (play) => {
    setUploadPlayArtSelected(play)
    setUploadPlayArtDialog(true)
  }

  const closeAlert = () => {
    setOpenAlert(false)
  }

  function UploadPlayArtButton (props) {
    return (
        <Button startIcon={<PhotoCamera/>} onClick={(e) => props.handleClick(props.play)}>
          <Typography variant="overline">Upload</Typography>
        </Button>
    )
  }

  const playArtUrl = (play) => {
    return 'https://cdn.simwinsports.com/development/assets/plays/' + play._id + '.png'
  }

  function ViewPlayArtButton (props) {
    return (

        <PopupState variant="popover" popupId="demo-popup-popover">
          {(popupState) => (
              <div>
                <Button startIcon={<Visibility/>} {...bindTrigger(popupState)}>
                  <Typography variant="overline">View</Typography>
                </Button>
                <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left'
                    }}
                >
                  <img
                      src={`${playArtUrl(props.play)}?w=248&auto=format&` + Math.random()}
                      srcSet={`${playArtUrl(props.play)}?w=248&auto=format&dpr=2 2x`}
                      alt={props.play.name}
                      loading="lazy"
                      width={400}
                  />

                </Popover>
              </div>
          )}
        </PopupState>

    )
  }

  function EditPlayButton (props) {
    return (
        <Button startIcon={<EditIcon/>} onClick={(e) => props.handleClick(props.play)}>
          <Typography variant="overline">Edit</Typography>
        </Button>
    )
  }

  function RenamePlayButton (props) {
    return (
        <Button startIcon={<EditAttributes/>} onClick={(e) => props.handleClick(props.play)}>
          <Typography variant="overline">Rename</Typography>
        </Button>
    )
  }

  function DuplicatePlayButton (props) {
    return (
        <Button startIcon={<FileCopyIcon/>} onClick={(e) => props.handleClick(props.play)}>
          <Typography variant="overline">Duplicate</Typography>
        </Button>
    )
  }

  function DeletePlayButton (props) {
    return (
        <Button startIcon={<DeleteIcon/>} onClick={(e) => props.handleClick(props.play)}>
          <Typography variant="overline">Delete</Typography>
        </Button>
    )
  }

  const SaveAlert = (props) => {
    return (
          <Dialog
              open={props.open}
              onClose={props.onClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Did you assign jobs to all players?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                In order to save a play all players must have at least one job assigned.
                Please review all of your player positions and job assignments.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
    )
  }

  return (
      <div style={{ width: '100%', height: '100vh' }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <SaveAlert open={openAlert} onClose={closeAlert}/>
        <MakePlayDialog
            trigger={makePlayDialog}
            setTrigger={setMakePlayDialog}
            onSetPlayers={setPlayers}
            jobs={makeSelectedJobs}
            makePlay={makePlay}
        />
        <EditPlayDialog
            trigger={editPlayDialog}
            setTrigger={setEditPlayDialog}
            onSetPlayers={setPlayers}
            play={editSelected}
            jobs={editSelectedJobs}
            editPlay={editPlay}
        />
        <RenamePlayDialog
            trigger={renamePlayDialog}
            setTrigger={setRenamePlayDialog}
            play={renameSelected}
            renamePlay={renamePlay}
        />
        <DuplicatePlayDialog
            trigger={duplicatePlayDialog}
            setTrigger={setDuplicatePlayDialog}
            play={duplicateSelected}
            duplicatePlay={duplicatePlay}
        />
        <DeletePlayDialog
            trigger={deletePlayDialog}
            setTrigger={setDeletePlayDialog}
            play={deleteSelected}
            deletePlay={deletePlay}
        />
        <UploadPlayArtDialog
            trigger={uploadPlayArtDialog}
            setTrigger={setUploadPlayArtDialog}
            play={uploadPlayArtSelected}
            uploadPlayArt={uploadPlayArt}
        />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >

          <Button color="primary" startIcon={<AddIcon/>} onClick={handleMakePlayClick}>
            Make Play
          </Button>

          <div style={{ height: 780, width: '100%' }}>
            <DataGrid
                sx={{
                  m: 2,
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main'
                  }
                }}
                getRowId={(row) => row?._id}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'desc' }]
                  }
                }}
                sortingOrder={['desc', 'asc']}
                rows={playData}
                columns={columns}
                pageSize={5}
                pageSizeOptions={[10, 50, 100]}
                rowsPerPageOptions={[5]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 }
                  }
                }}
            />
          </div>
        </Grid>
      </div>
  )
}
