import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import axios from 'axios'
import { Step, StepLabel, Stepper, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AssignJobs from '../AssignJobs'
import SelectBackground from '../SelectBackground'
import SelectPlayType from '../SelectPlayType'
import SelectOffenseFormation from '../SelectOffenseFormation'
import SelectDefenseFormation from '../SelectDefenseFormation'
import SelectSpecialFormation from '../SelectSpecialFormation'
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function MakePlayDialog (props) {
  const [open, setOpen] = React.useState(false)
  const [play, setPlay] = React.useState({})
  const [jobs, setJobs] = React.useState([])

  const [activeStep, setActiveStep] = React.useState(0)
  const [background, setBackground] = React.useState('')
  const [playType, setPlayType] = React.useState({})
  const [formation, setFormation] = React.useState('')
  const [stepsCompleted, setStepsCompleted] = React.useState([])
  const [activeStepCompleted, setActiveStepCompleted] = React.useState(false)

  const completeStep = (val) => {
    const currentStepsCompleted = stepsCompleted
    currentStepsCompleted[activeStep] = val
    setStepsCompleted(currentStepsCompleted)
    setActiveStepCompleted(val)
  }

  const handleNext = () => {
    const newStep = activeStep + 1
    setActiveStepCompleted(stepsCompleted[newStep])
    setActiveStep(newStep)
  }

  const handleBack = () => {
    const newStep = activeStep - 1
    setActiveStep(newStep)
    setActiveStepCompleted(stepsCompleted[newStep])
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  async function initJobs () {
    await axios.get('/v1/webapi/playeditor_jobs').then((response) => {
      const dd = response.data.formation
      const tmpJobs = []
      const jobsData = []
      for (let d = 0; d < dd.length; d++) {
        for (let e = 0; e < dd[d].items.length; e++) {
          jobsData.push(dd[d].items[e])
        }
      }
      // console.log(jobs_data[0])

      for (let j = 0; j < play.jobs.length; j++) {
        const jobarr = play.jobs[j].possibleJobs
        const jobparts = []
        // console.log('jobss1', jobarr)
        for (let i = 0; i < jobarr.length; i++) {
          const nm = jobarr[i].job
          const jd = jobsData.filter(jd => jd.value === nm)
          // console.log('i', jd, jobarr[i].job, nm)
          jobparts.push({
            job: jd[0].value,
            size: jd[0].size,
            offsetX: jd[0].offsetX,
            offsetY: jd[0].offsetY,
            imgUrl: jd[0].imageUrl
          })
        }
        // console.log('lobpart', jobparts, j)

        tmpJobs.push(jobparts)
        setJobs(tmpJobs)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const handleClickOpen = () => {
    initJobs()
    setOpen(true)
  }

  const reset = () => {
    setActiveStep(0)
    setBackground('')
    setPlayType({})
    setFormation('')
    setStepsCompleted([])
    setActiveStepCompleted(false)
  }

  const handleClose = () => {
    props.setTrigger(false)
    reset()
  }

  const handleSaveClick = () => {
    if (props?.makePlay(play)) {
      props.setTrigger(false)
      reset()
    }
  }

  const chooseName = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, ml: 15, mr: 5 }}>
          <Typography variant='button'>Enter a name for your play:&nbsp;&nbsp;</Typography>
          <TextField
              required
              id="outline-required"
              helperText="Descriptive Play Name"

              variant="filled"
              sx={{ width: '40%' }}
          />

        </Box>
    )
  }

  const chooseBackground = () => {
    return (
        <SelectBackground play={play} background={background} setBackground={setBackground}
                          completeStep={completeStep}/>
    )
  }

  const choosePlayType = () => {
    return (
        <SelectPlayType play={play} playType={playType} setPlayType={setPlayType} completeStep={completeStep}/>
    )
  }

  const chooseFormation = () => {
    if (!playType) {
      return
    }

    if (playType?.type === 'special_team_play') {
      return (
          <SelectSpecialFormation play={play} formation={formation} setFormation={setFormation}
                                  completeStep={completeStep}/>
      )
    } else if (playType?.type === 'defense_play') {
      return (
          <SelectDefenseFormation play={play} formation={formation} setFormation={setFormation}
                                  completeStep={completeStep}/>
      )
    } else {
      return (
          <SelectOffenseFormation play={play} formation={formation} setFormation={setFormation}
                                  completeStep={completeStep}/>
      )
    }
  }

  const assignJobs = () => {
    return (
        <AssignJobs play={play} jobs={props.jobs} completeStep={completeStep} onSetPlayers={props.onSetPlayers}/>
    )
  }

  const getTab = (playType) => {
    switch (activeStep) {
      case 0:
        return chooseBackground()
      case 1:
        return choosePlayType()
      case 2:
        return chooseFormation()
      case 3:
        return assignJobs()
    }
  }

  return (
        <Dialog
            fullScreen
            open={props.trigger}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative', mb: 5 }}>
            <Toolbar>
              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
              >
                <CloseIcon/>
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Make Play
              </Typography>
              <Button autoFocus color="inherit" onClick={handleSaveClick}>
                Save
              </Button>
            </Toolbar>
          </AppBar>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} sx={{ ml: 5, mr: 5, mb: 1 }}>
              <Step key={0}>
                <StepLabel>Choose background</StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>Choose play type</StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>Choose formation</StepLabel>
              </Step>
              <Step key={3}>
                <StepLabel>Assign jobs</StepLabel>
              </Step>
            </Stepper>
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, ml: 5, mr: 5 }}>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }}/>
                <Button disabled={!activeStepCompleted} onClick={handleNext}>
                  {activeStep === 3 ? '' : 'Next'}
                </Button>
              </Box>
              <Box>
                {getTab(playType)}
              </Box>
            </React.Fragment>

          </Box>
        </Dialog>
  )
}

MakePlayDialog.propTypes = {
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired,
  makePlay: PropTypes.any.isRequired
}
