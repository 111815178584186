import React from 'react'
import './makeplay.css'
import { Icon } from '@iconify/react'
import 'aos/dist/aos.css'

function SelectBackground (props) {
  const checkBackground = (state) => {
    return props?.background === state
  }

  const setBackground = (state) => {
    if (!state) {
      return
    }

    if (props.setBackground) {
      props.setBackground(state)
    }

    if (props.completeStep) {
      props.completeStep(true)
    }

    if (props.play) {
      props.play.background = state
    }
  }

  return (
      <div className="popup2">
        <div className="popbody">
          <div className="pop">
            <div className="mainarea">
              <div className="backgroundtitle">Select Background</div>
              <div className="popselection">
                <div style={checkBackground('Mid-Field') ? { backgroundColor: 'rgb(7, 41, 95)' } : {}}
                     className="select" onClick={() => {
                       setBackground('Mid-Field')
                     }}>
                  {checkBackground('Mid-Field')
                    ? (
                          <Icon icon="akar-icons:circle-check" style={{ color: 'rgb(82, 143, 179)' }}/>)
                    : (<Icon icon="akar-icons:circle"/>
                      )}
                  <span>Mid-Field</span>
                  <div className="selectpic"></div>
                  <img src="../assets/background-mid.png" alt=""/>
                </div>
                <div style={checkBackground('Red Zone') ? { backgroundColor: 'rgb(7, 41, 95)' } : {}}
                     className="select" onClick={() => {
                       setBackground('Red Zone')
                     }}>

                  {checkBackground('Red Zone')
                    ? (
                          <Icon icon="akar-icons:circle-check" style={{ color: 'rgb(82, 143, 179)' }}/>)
                    : (<Icon icon="akar-icons:circle"/>
                      )}
                  <span>Red Zone</span>
                  <div className="selectpic">
                    <img src="../assets/background-redzone.png" alt=""/>
                  </div>
                </div>
                <div style={checkBackground('Backed-up') ? { backgroundColor: 'rgb(7, 41, 95)' } : {}}
                     className="select" onClick={() => {
                       setBackground('Backed-up')
                     }}>

                  {checkBackground('Backed-up')
                    ? (
                          <Icon icon="akar-icons:circle-check" style={{ color: 'rgb(82, 143, 179)' }}/>)
                    : (<Icon icon="akar-icons:circle"/>
                      )}

                  <span>Backed-up</span>
                  <div className="selectpic"><img src="../assets/background-backedup.png" alt=""/></div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
  )
}

export default SelectBackground
