import React, { useState } from 'react'
import Button from '@mui/material/Button'
import axios from 'axios'
import './play.css'
import 'aos/dist/aos.css'
import MakePlaybookDialog from './dialogs/MakePlaybookDialog'
import RenamePlaybookDialog from './dialogs/RenamePlaybookDialog'
import AddPlaysToPlaybookDialog from './dialogs/AddPlaysToPlaybookDialog'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DuplicatePlaybookDialog from './dialogs/DuplicatePlaybookDialog'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Icon } from '@iconify/react'
import DeletePlaybookDialog from './dialogs/DeletePlaybookDialog'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddBoxIcon from '@mui/icons-material/AddBox'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import ViewPlaybookDialog from './dialogs/ViewPlaybookDialog'
import {PanoramaFishEye, Preview, Visibility} from "@mui/icons-material";

const playUrl = '/v1/webapi/playbook_entries'
const playbookUrl = '/v1/webapi/playbook_schemes'
toast.configure()

function Playbooks () {
  const [playbookState, setPlaybookState] = useState(null)

  React.useEffect(() => {
    axios.get(playbookUrl).then((response) => {
      if (response.data.length === 0) {
        console.log('sk')
      } else {
        setPlaybookState(response.data)
      }
    }).catch((error) => {
      console.log(error)
    })

    axios
      .get(playUrl)
      .then((response) => {
        if (response.data.length !== 0) {
          const allPlays = response.data
          setPlayList(allPlays)

          const playMap = {}
          allPlays.map((play) => {
            playMap[play._id] = play
            return play
          })
          setPlayMap(playMap)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const [viewPlaybookDialog, setViewPlaybookDialog] = useState(false)
  const [viewSelected, setViewSelected] = useState({})
  const [viewSelectedEntries, setViewSelectedEntries] = useState({})

  const [makePlaybookDialog, setMakePlaybookDialog] = useState(false)
  const [renamePlaybookDialog, setRenamePlaybookDialog] = useState(false)
  const [renameSelected, setRenameSelected] = useState({})

  const [duplicatePlaybookDialog, setDuplicatePlaybookDialog] = useState(false)
  const [duplicateSelected, setDuplicateSelected] = useState({})

  const [deletePlaybookDialog, setDeletePlaybookDialog] = useState(false)
  const [deleteSelected, setDeleteSelected] = useState({})

  const [addPlayDialog, setAddPlayDialog] = useState(false)
  const [addPlaySelected, setAddPlaySelected] = useState({})
  const [addPlaySelectedEntries, setAddPlaySelectedEntries] = useState({})

  const [playList, setPlayList] = useState([])
  const [playMap, setPlayMap] = useState({})

  const updatePlaybookState = () => {
    axios
      .get(playbookUrl)
      .then((response) => {
        if (response.data.length === 0) {
          setPlaybookState(null)
        } else {
          setPlaybookState(response.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const makePlaybook = (playbook) => {
    axios
      .post(playbookUrl, playbook)
      .then((response) => {
        updatePlaybookState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function deletePlaybook (playbook) {
    axios
      .delete(playbookUrl + '/' + playbook._id)
      .then((response) => {
        updatePlaybookState()
        notify(-1, `${playbook.playbookname} DELETED`)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const renamePlaybook = (playbook) => {
    axios
      .put(playbookUrl + '/' + playbook._id, playbook)
      .then((response) => {
        updatePlaybookState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const duplicatePlaybook = (playbook) => {
    axios
      .post(playbookUrl, playbook)
      .then((response) => {
        updatePlaybookState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addPlaysToPlaybook = (playbook) => {
    axios
      .put(playbookUrl + '/' + playbook._id, playbook)
      .then((response) => {
        updatePlaybookState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const removePlaysFromPlaybook = (playbook) => {

    axios
      .put(playbookUrl + '/' + playbook._id, playbook)
      .then((response) => {
        const playEntries = getPlayEntries(playbook, playList)
        setViewSelectedEntries(playEntries)
        setViewSelected(playbook)
        updatePlaybookState()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleViewPlaybookClick = (playbook) => {
    const playEntries = getPlayEntries(playbook, playList)
    setViewSelectedEntries(playEntries)
    setViewSelected(playbook)
    setViewPlaybookDialog(true)
  }

  const handleRenamePlaybookClick = (playbook) => {
    setRenameSelected(playbook)
    setRenamePlaybookDialog(true)
  }
  const handleDuplicatePlaybookClick = (playbook) => {
    setDuplicateSelected(playbook)
    setDuplicatePlaybookDialog(true)
  }

  const handleDeletePlaybookClick = (playbook) => {
    setDeleteSelected(playbook)
    setDeletePlaybookDialog(true)
  }

  const handleMakePlaybookClick = (playbook) => {
    setMakePlaybookDialog(true)
  }

  const getPlayEntries = (playbook, playlist) => {
    const playEntries = []

    playbook?.offense?.map((entry) => {
      playEntries.push(entry)
      return entry
    })
    playbook?.defense?.map((entry) => {
      playEntries.push(entry)
      return entry
    })
    playbook?.special?.map((entry) => {
      playEntries.push(entry)
      return entry
    })

    return playEntries
  }

  const getEnabledPlayEntries = (playbook, playlist) => {
    const playEntries = {}

    playbook?.offense?.map((entry) => {
      playEntries[entry.playbookEntryIDs] = true
      return entry
    })
    playbook?.defense?.map((entry) => {
      playEntries[entry.playbookEntryIDs] = true
      return entry
    })
    playbook?.special?.map((entry) => {
      playEntries[entry.playbookEntryIDs] = true
      return entry
    })

    return playEntries
  }

  const handleAddPlayToPlaybookClick = (playbook) => {
    const enabledPlayEntries = getEnabledPlayEntries(playbook, playList)
    setAddPlaySelectedEntries(enabledPlayEntries)
    setAddPlaySelected(playbook)
    setAddPlayDialog(true)
  }
  const notify = (id, msg) => {
    if (id === -1) {
      toast.success(`${msg}!`, { position: toast.POSITION.TOP_CENTER, toastId: id })
    } else {
      toast.error(`${msg}!`, { position: toast.POSITION.TOP_CENTER, toastId: id })
    }
  }

  function ViewPlaybookButton (props) {
    return (
        <Button startIcon={<Visibility/>} onClick={(e) => props.handleClick(props.playbook)}>
          <Typography variant="overline">View</Typography>
        </Button>
    )
  }

  function RenamePlaybookButton (props) {
    return (
        <Button startIcon={<EditIcon/>} onClick={(e) => props.handleClick(props.playbook)}>
          <Typography variant="overline">Rename</Typography>
        </Button>
    )
  }

  function DuplicatePlaybookButton (props) {
    return (
        <Button startIcon={<FileCopyIcon/>} onClick={(e) => props.handleClick(props.playbook)}>
          <Typography variant="overline">Duplicate</Typography>
        </Button>
    )
  }

  function AddPlayButton (props) {
    return (
        <Button startIcon={<AddBoxIcon/>} onClick={(e) => props.handleClick(props.playbook)}>
          <Typography variant="overline">Add Play</Typography>
        </Button>
    )
  }

  function DeletePlaybookButton (props) {
    return (
        <Button startIcon={<DeleteIcon/>} onClick={(e) => props.handleClick(props.playbook)}>
          <Typography variant="overline">Delete</Typography>
        </Button>
    )
  }

  function getPlaybookName (params) {
    return `${params.row.TeamName || ''}---${params.row.playbookname || ''}`
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: getPlaybookName
    },
    {
      field: 'actions',
      type: 'actions',
      width: 680,
      getActions: (params) => {
        return [
          <ViewPlaybookButton key={0} playbook={params.row} handleClick={handleViewPlaybookClick}/>,
          <RenamePlaybookButton key={1} playbook={params.row} handleClick={handleRenamePlaybookClick}/>,
          <DuplicatePlaybookButton key={2} playbook={params.row} handleClick={handleDuplicatePlaybookClick}/>,
          <AddPlayButton key={3} playbook={params.row} handleClick={handleAddPlayToPlaybookClick}/>,
          <DeletePlaybookButton key={4} playbook={params.row} handleClick={handleDeletePlaybookClick}/>
        ]
      }
    }

  ]

  return (
      <div style={{ width: '100%', height: '100vh' }}>
        <ViewPlaybookDialog
            trigger={viewPlaybookDialog}
            setTrigger={setViewPlaybookDialog}
            playbook={viewSelected}
            entries={viewSelectedEntries}
            playlist={playList}
            playmap={playMap}
            removePlaysFromPlaybook={removePlaysFromPlaybook}
        />

        <MakePlaybookDialog
            trigger={makePlaybookDialog}
            setTrigger={setMakePlaybookDialog}
            makePlaybook={makePlaybook}
        />
        <RenamePlaybookDialog
            trigger={renamePlaybookDialog}
            setTrigger={setRenamePlaybookDialog}
            playbook={renameSelected}
            renamePlaybook={renamePlaybook}
        />
        <DuplicatePlaybookDialog
            trigger={duplicatePlaybookDialog}
            setTrigger={setDuplicatePlaybookDialog}
            playbook={duplicateSelected}
            duplicatePlaybook={duplicatePlaybook}
        />
        <AddPlaysToPlaybookDialog
            trigger={addPlayDialog}
            setTrigger={setAddPlayDialog}
            playbook={addPlaySelected}
            playlist={playList}
            playmap={playMap}
            checked={addPlaySelectedEntries}
            setChecked={setAddPlaySelectedEntries}
            addPlaysToPlaybook={addPlaysToPlaybook}
        />
        <DeletePlaybookDialog
            trigger={deletePlaybookDialog}
            setTrigger={setDeletePlaybookDialog}
            playbook={deleteSelected}
            deletePlaybook={deletePlaybook}
        />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
        >
          <Button color="primary" startIcon={<AddIcon/>} onClick={handleMakePlaybookClick}>
            Make Playbook
          </Button>

          <div style={{ height: 780, width: '100%' }}>
            <DataGrid
                sx={{
                  m: 2,
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main'
                  }
                }}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }]
                  }
                }}
                sortingOrder={['desc', 'asc']}
                getRowId={(row) => row?._id}
                rows={playbookState || []}
                columns={columns}
                pageSize={5}
                pageSizeOptions={[10, 50, 100]}
                rowsPerPageOptions={[5]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 }
                  }
                }}
            />
          </div>
        </Grid>

      </div>
  )
}

export default Playbooks
