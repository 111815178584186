import React from 'react'
import './addplay.css'
import { Checkbox, Drawer, Grid, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'

export default function AddPlaysToPlaybookDialog (props) {
  const [selected, setSelected] = React.useState()
  const [plays, setPlays] = React.useState(props.playmap ?? {})
  const [checked, setChecked] = React.useState(props.checked ?? {})

  React.useEffect(() => {
    console.log('')
  })

  const handleCheckboxClick = (play, value) => {
    if (value) {
      props.checked[play._id] = true
    } else {
      props.checked[play._id] = false
    }
  }

  const handleAddPlaysClick = () => {
    let numOffensePlays = 0
    let numDefensePlays = 0
    let numSpecialPlays = 0

    props.playbook.offense = []
    props.playbook.defense = []
    props.playbook.special = []
    for (const playId in props.checked) {
      const play = props.playmap[playId]
      const entry = {
        name: play.name,
        value: play.play_value + '_' + play.formation_value,
        description: 'This is a ' + play.play_value + ' play in the ' + play.formation_value + ' formation',
        playbookEntryIDs: play._id
      }
      switch (play.play_type) {
        case 'offense_play':
          numOffensePlays++
          console.log('Defense scheme ' + numOffensePlays)
          props.playbook.offense.push(entry)
          break
        case 'defense_play':
          numDefensePlays++
          console.log('Defense scheme ' + numDefensePlays)
          props.playbook.defense.push(entry)
          break
        case 'special_play':
          numSpecialPlays++
          console.log('Special scheme ' + numSpecialPlays)
          props.playbook.special.push(entry)
          break
      }
    }

    props.addPlaysToPlaybook(props.playbook)
    props.setTrigger(false)
  }

  const playclicked = (p) => {
    const l = document.getElementsByClassName('created_playlist')
    for (let i = 0; i < l.length; i++) {
      l[i].style.border = '2px solid black'
    }

    setSelected(p)
    document.getElementById(p._id).style.border = '4px solid rgb(140, 200, 253)'
  }
  const done = () => {
    play(selected)
    props.setTrigger(false)
    setSelected('')
  }

  const close = () => {
    props.setTrigger(false)
  }

  const playArtUrl = (play) => {
    return 'https://cdn.simwinsports.com/development/assets/plays/' + play._id + '.png'
  }

  const PlayArt = ({ play }) => {
    const url = 'https://cdn.simwinsports.com/development/assets/plays/' + play._id + '.png'
    return (
        <img
            src={`${url}?w=248&auto=format`}
            srcSet={`${url}?w=248&auto=format&dpr=2 2x`}
            alt={play.name}
            loading="lazy"
        />
    )
  }

  return (
    props.playlist
      ? <Drawer
              anchor={'right'}
              open={props.trigger}
              onClose={close}
          >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <Grid container spacing={2} direction="row" justifyContent="space-between">
                <Grid item xs={10}><Button variant="contained" onClick={handleAddPlaysClick}>Add Plays</Button></Grid>

                <Grid item xs={2}>
                  <IconButton onClick={close}>
                    <CloseIcon/>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <Divider/>
            <ImageList sx={{ width: 500 }} cols={1}>
              {props.playlist.map((play) => (
                  <ImageListItem key={play._id}>
                    <img
                        src={`${playArtUrl(play)}?w=248&auto=format`}
                        srcSet={`${playArtUrl(play)}?w=248&auto=format&dpr=2 2x`}
                        alt={play.name}
                        loading="lazy"
                    />
                    {/* <Tooltip title={play.name}> */}
                      <ImageListItemBar
                          title={play.name}
                          subtitle={play.play_type}
                          position="top"
                          actionIcon={
                            <Checkbox key={play._id} checked={props?.checked[play?._id]} onChange={ (e) => {
                              handleCheckboxClick(play, e.target.checked)
                            }}/>
                          }

                      />
                    {/* </Tooltip> */}
                  </ImageListItem>
              ))}
            </ImageList>

          </Drawer>

      : ''

  )
}

AddPlaysToPlaybookDialog.propTypes = {
  playbook: PropTypes.any.isRequired,
  addPlaysToPlaybook: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired,
  playmap: PropTypes.any.isRequired,
  playlist: PropTypes.any.isRequired,
  checked: PropTypes.any.isRequired
}
