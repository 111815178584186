import React from 'react'
import './addplay.css'
import {Checkbox, Drawer, Fab, Grid, ImageList, ImageListItem, ImageListItemBar, Stack, Tooltip} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import EditIcon from "@mui/icons-material/Edit";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";

export default function ViewPlaybookDialog (props) {
  const [selected, setSelected] = React.useState()
  const [playbook, setPlaybook] = React.useState(props.playbook ?? {})
  const [success, setSuccess] = React.useState(false)

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700]
      }
    })
  }

  React.useEffect(() => {
    console.log('')
  })

  const handleClose = () => {
    props?.setTrigger(false)
  }

  const handleEditEntryClicked = (entry) => {
    // TBD
  }

  const handleRemoveEntryClicked = (entry) => {
    const offense = props.playbook.offense?.filter((p) => p.playbookEntryIDs !== entry.playbookEntryIDs)
    const defense = props.playbook.defense?.filter((p) => p.playbookEntryIDs !== entry.playbookEntryIDs)
    const special = props.playbook.special?.filter((p) => p.playbookEntryIDs !== entry.playbookEntryIDs)
    props.playbook.offense = offense ?? []
    props.playbook.defense = defense ?? []
    props.playbook.special = special ?? []
    props.removePlaysFromPlaybook(props.playbook)
    setPlaybook(props.playbook)
  }

  const playArtUrl = (entry) => {
    return 'https://cdn.simwinsports.com/development/assets/plays/' + entry.playbookEntryIDs + '.png'
  }

  return (
    props.playbook && props.entries && props.playbook._id
      ? <Dialog
            fullScreen
            open={props.trigger}
            onClose={handleClose}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <AppBar sx={{ position: 'relative', mb: 5 }}>
                <Toolbar>
                  <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                  >
                    <CloseIcon/>
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    View Playbook
                  </Typography>
                </Toolbar>
              </AppBar>
            </DialogTitle>
            <Divider/>
            <Grid container justifyContent="center">
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="center" sx={{ mb:5 }}>
                  <Typography variant="h2">{props.playbook.playbookname.toUpperCase()}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={10}>
            <ImageList sx={{ width: "100%" }} cols={3} rowHeight={400} gap={20}>
              {props.entries?.map((entry) => (
                  <ImageListItem key={entry.playbookEntryIDs} >
                    <img
                        src={`${playArtUrl(entry)}?w=248&auto=format`}
                        srcSet={`${playArtUrl(entry)}?w=248&auto=format&dpr=2 2x`}
                        alt={entry.value}
                        loading="lazy"
                    />
                    <Tooltip title={entry.name}>
                      <ImageListItemBar
                          subtitle={entry.name}

                          position="top"
                          actionIcon={
                        <Box>
                          <IconButton
                              sx={{ 'background-color': 'rgba(255, 255, 255, 0.8)', m: 1 }}
                              onClick={() => {
                                handleEditEntryClicked(entry)
                              }}>
                            <EditIcon sx={{ fontSize: 16 }}/>
                          </IconButton>
                          <IconButton
                              sx={{ 'background-color': 'rgba(255, 255, 255, 0.8)', m: 1 }}
                              onClick={() => {
                                handleRemoveEntryClicked(entry)
                              }}>
                            <DeleteIcon sx={{ fontSize: 16 }}/>
                          </IconButton>
                        </Box>
                          }

                      />
                    </Tooltip>
                  </ImageListItem>
              ))}
            </ImageList>
              </Grid>
            </Grid>

          </Dialog>

      : ''

  )
}

ViewPlaybookDialog.propTypes = {
  playbook: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired,
  playmap: PropTypes.any.isRequired,
  playlist: PropTypes.any.isRequired

}
