import React, { useEffect, useState } from 'react'
import './step3.css'
import 'aos/dist/aos.css'
import { PlaybookContext } from './PlaybookContextProvider'
import axios from 'axios'
import { Pagination, Stack } from '@mui/material'

function SelectSpecialFormation (props) {
  const [formationData, setFormationData] = useState()
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(3)
  const [itemsPerPage, setItemsPerPage] = useState(15)

  const handlePageChange = (event, pageNumber) => {
    setPage(pageNumber)
  }

  useEffect(() => {
    async function fetchFormationData () {
      const selectedFormationId = props.formation?.id
      if (selectedFormationId) {
        const v = document.getElementById(selectedFormationId)
        if (v) {
          v.style.border = '5px solid'
          v.style.borderColor = 'rgb(116 164 47)'

          if (props.completeStep) {
            props.completeStep(false)
          }
        }
      }

      if (formationData) {
        return
      }

      await axios.get('/v1/webapi/playeditor_formations?formation_type=special_formation').then((response) => {
        const formations = response.data.formations
        const selectedFormationId = props.formation?.id

        setFormationData(response.data)
        setPageCount(Math.ceil(formations.length / itemsPerPage))

        if (selectedFormationId) {
          let id = null
          for (let i = 0; i < formations.length; i++) {
            if (formations[i].id === props.formation.id) {
              id = formations[i].id
            }
            if (id) {
              const v = document.getElementById(id)
              v.style.border = '4px solid'
              v.style.borderColor = 'rgb(47 105 164)'

              if (props.completeStep) {
                props.completeStep(true)
              }
            }
          }
        }
      }).catch(error => {
        //  console.log(error);
      })
    }

    fetchFormationData()
  })

  const handleSelect = (id) => {
    const a = document.getElementsByClassName('picboxste3sep')
    for (let i = 0; i < a.length; i++) {
      a[i].style.border = ''
    }
    const v = document.getElementById(id)
    v.style.border = '4px solid'
    v.style.borderColor = 'rgb(47 105 164)'

    const formation = formationData.formations.find(formation => formation.id === id)
    if (props.setFormation) {
      props.setFormation(formation)
    }
    if (props.completeStep) {
      props.completeStep(true)
    }
    if (props.play) {
      props.play.formationId = formation.id
      props.play.formation_type = formation.formationType
      props.play.formation_value = formation.value
    }
  }

  return (
      <PlaybookContext.Consumer>
        {(value) => (
            <>
              {formationData
                ? (<>

                    <div
                        className="step3">

                      <div
                          className="mainstep3">
                        <div className="backgroundtitle">Select Special Formation</div>
                        <div
                            className="step3mainpics"
                            id="page1">
                          <div
                              className="step3row">
                            {formationData.formations.slice(0, 5).map((formation) => (
                                <div
                                    className="picboxste3"
                                    id={formation.id}
                                    onClick={() => handleSelect(formation.id)}
                                >
                                  <p>{formation.value}</p>
                                  <img alt=""
                                       value={formation.value}
                                       src={formation.imgUrl}/>
                                </div>
                            ))}
                          </div>
                          <div
                              className="step3row">
                            {formationData.formations.slice(5, 10).map((formation) => (
                                <div
                                    className="picboxste3"
                                    id={formation.id}
                                    onClick={() => handleSelect(formation.id)}
                                >
                                  <p>{formation.value}</p>
                                  <img alt=""
                                       value={formation.value}
                                       src={formation.imgUrl}/>
                                </div>
                            ))}
                          </div>
                          <div
                              className="step3row">
                            {formationData.formations.slice(8, 12).map((formation) => (
                                <div
                                    className="picboxste3"
                                    id={formation.id}
                                    onClick={() => handleSelect(formation.id)}
                                >
                                  <p>{formation.value}</p>
                                  <img alt=""
                                       value={formation.value}
                                       src={formation.imgUrl}/>
                                </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div
                          className="bottomdef"

                      >
                        <Stack
                            sx={{
                              width: '100%',
                              position: 'fixed',
                              bottom: 50,
                              'align-items': 'center'
                            }}
                        >
                          <Pagination count={pageCount} page={page} onChange={handlePageChange}/>
                        </Stack>

                      </div>

                    </div>

                  </>)
                : ''}
            </>

        )}
      </PlaybookContext.Consumer>
  )
}

export default SelectSpecialFormation
