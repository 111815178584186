import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import AssignJobs from '../AssignJobs'
import PropTypes from 'prop-types'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions, DialogContent } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function EditPlayDialog (props) {
  const handleClose = () => {
    props?.setTrigger(false)
  }

  const handleSaveClick = () => {
    if (props?.editPlay(props?.play)) {
      props.setTrigger(false)
    }
  }


  return (
        <Dialog
            fullScreen
            open={props.trigger}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
              >
                <CloseIcon/>
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Play
              </Typography>
              <Button autoFocus color="inherit" onClick={handleSaveClick}>
                Save
              </Button>
            </Toolbar>
          </AppBar>
          <AssignJobs play={props.play} jobs={props.jobs} onSetPlayers={props.onSetPlayers}/>
        </Dialog>
  )
}

EditPlayDialog.propTypes = {
  play: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired,
  editPlay: PropTypes.any.isRequired
}
