import React, { useEffect, useState } from 'react'
import './makeplaybook.css'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions, DialogContent, TextField } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

function DuplicatePlayDialog (props) {
  const [playName, setPlayName] = useState(props?.play?.name ?? '')

  useEffect(() => {
    if (props?.play) {
      props.play.name = playName
    }
  }, [playName])

  const handlePlayName = (e) => {
    setPlayName(e.target.value)
  }

  const handleClose = () => {
    props.setTrigger(false)
  }

  const save = () => {
    const copy = Object.assign({}, props.play)

    copy._id = ''
    copy.name = playName
    props.duplicatePlay(copy)
    props.setTrigger(false)
  }
  const cancel = () => {
    props.setTrigger(false)
  }

  return (
      <Dialog open={props.trigger} onClose={handleClose}>
        <DialogTitle>Duplicate Play</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for the new play.
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="string"
              fullWidth
              variant="standard"
              onChange={handlePlayName}
              sx={{ mb: 3, width: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
  )
}

DuplicatePlayDialog.propTypes = {
  play: PropTypes.any.isRequired,
  duplicatePlay: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired
}
export default DuplicatePlayDialog
