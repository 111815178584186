import React from 'react'
import './player.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Player = ({ player, position, selection, formation, flipped }) => {
  const { id, value, displayValue, jobs } = player
  const imgSize = 'small'
  const imgUrl = 'assets/circle-outline.svg'
  {
    console.log('a', player)
  }
  React.useEffect(() => {
    Aos.init({ duration: 800 })
    return () => {

    }
  }, [])

  return (
      <div
          style={
            !flipped
              ? { top: `${position.top}`, left: `${position.left}`, zIndex: '2' }
              : { top: `${position.top}`, right: `${position.left}`, zIndex: '2' }
          }
          className="player"

          data-aos="zoom-in-up"
      >
        <div>
          {formation.formation_type !== 'defense_formation'
            ? (<>

                {player.jobAssigned
                  ? (<>
                      {player.selected
                        ? (<>
                            <img
                                onClick={() => selection(id)}
                                onDoubleClick={() => selection('')}
                                data-aos="zoom-in"
                                style={{ width: '60px', position: 'relative', marginTop: '5%', left: '-5%' }}
                                className={`player-image ${imgSize} topup`}
                                src={`${window.location.origin}/${'assets/player-selected.png'}`}

                            />
                          </>)
                        : (<img
                              onClick={() => selection(id)}
                              onDoubleClick={() => selection('')}
                              style={{ opacity: '1', marginTop: '10%', left: '-5%' }}
                              className={`player-image ${'small'}  topup`}
                              src={`${window.location.origin}/${'assets/player.png'}`}
                          />)}

                      <div onClick={() => selection(id)}
                           onDoubleClick={() => selection('')} style={{ cursor: 'pointer' }}
                           className="player-name  topup">
                        {(value.length > 1)
                          ? (
                                <h2 style={{ marginLeft: '-25%' }}>
                                  {value}
                                </h2>
                            )
                          : (
                                <h2>
                                  {value}
                                </h2>
                            )}

                      </div>

                    </>)
                  : (<>
                      {player.selected
                        ? (<>
                            <img
                                onClick={() => selection(id)}
                                onDoubleClick={() => selection('')}
                                style={{ width: '60px', position: 'relative', marginTop: '5%', left: '-5%' }}
                                className={`player-image ${imgSize}  topup`}
                                src={`${window.location.origin}/${'assets/player-selected.png'}`}

                                data-aos="zoom-in"/>
                          </>)
                        : (
                              <img
                                  onClick={() => selection(id)}
                                  onDoubleClick={() => selection('')}
                                  style={{
                                    opacity: '0.5',
                                    width: '60px',
                                    position: 'relative',
                                    marginTop: '5%',
                                    left: '-5%'
                                  }}
                                  className={`player-image ${imgSize}  topup`}
                                  src={`${window.location.origin}/${'assets/player.png'}`}
                              />
                          )}
                      <div style={{ cursor: 'pointer' }} onClick={() => selection(id)}
                           onDoubleClick={() => selection('')} className="player-name">
                        {(displayValue.length > 1)
                          ? (
                                <h2 style={{ marginLeft: '-25%' }}>
                                  {displayValue}
                                </h2>
                            )
                          : (
                                <h2>
                                  {displayValue}
                                </h2>
                            )}

                      </div>

                    </>)}

              </>)
            : (<>
                {player.jobAssigned
                  ? (<>
                      {player.selected
                        ? (<>
                            <img
                                onClick={() => selection(id)}
                                onDoubleClick={() => selection('')}
                                data-aos="zoom-in"
                                style={{ width: '60px', position: 'relative', marginTop: '5%', left: '-5%' }}
                                className={`player-image ${imgSize}`}
                                src={`${window.location.origin}/${'assets/player-defense-selected.png'}`}
                                alt=""
                            />
                          </>)
                        : (<img
                              onClick={() => selection(id)}
                              onDoubleClick={() => selection('')}
                              style={{ opacity: '1', marginTop: '10%', left: '-5%' }}
                              className={`player-image ${'small'}`}
                              src={`${window.location.origin}/${'assets/player-defense.png'}`} alt=""
                          />)}

                    </>)
                  : (<>
                      {player.selected
                        ? (<>
                            {console.log(player)}
                            <img
                                onClick={() => selection(id)}
                                onDoubleClick={() => selection('')}
                                data-aos="zoom-in"
                                style={{ width: '60px', position: 'relative', marginTop: '5%', left: '-5%' }}
                                className={`player-image ${imgSize}`}
                                src={`${window.location.origin}/${'assets/player-defense-selected.png'}`}
                                alt=""
                            />
                          </>)
                        : (
                              <img
                                  onClick={() => selection(id)}
                                  onDoubleClick={() => selection('')}
                                  style={{
                                    opacity: '0.5',
                                    width: '60px',
                                    position: 'relative',
                                    marginTop: '5%',
                                    left: '-5%'
                                  }}
                                  className={`player-image ${imgSize}`}
                                  src={`${window.location.origin}/${'assets/player-defense.png'}`} alt=""
                              />
                          )

                      }

                    </>)}
                <div style={{ cursor: 'pointer' }} onClick={() => selection(id)}
                     onDoubleClick={() => selection('')} className="player-name-defense  topup">
                  {(displayValue.length > 1)
                    ? (
                          <h2 style={{ marginLeft: '-25%' }}>
                            {displayValue}
                          </h2>
                      )
                    : (
                          <h2>
                            {displayValue}
                          </h2>
                      )}

                </div>

              </>)

          }</div>

      </div>
  )
}

export default Player
