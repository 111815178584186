import React from 'react'
import './view.css'
// import JOBS_DATA from "./strategies/jobs";
import MainAppBar from './MainAppBar'
import {Backdrop, CircularProgress, Container, Tab} from '@mui/material'
import EditPlayDialog from './dialogs/EditPlayDialog'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import Plays from './Plays'
import Playbooks from './Playbooks'

export default function Home () {
  const [value, setValue] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps (index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  function TabPanel (props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
          {value === index && (
              <Box sx={{ p: 3 }}>
                {children}
              </Box>
          )}
        </div>
    )
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'twice',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      width: 300,
      renderCell: (params) => {
        return (
            <Container>
              <EditPlayDialog play={params.row}/>
            </Container>
        )
      }
    }

  ]

  return (
      <div>
        <div>
          <div>
            <MainAppBar/>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Plays" {...a11yProps(0)} />
                <Tab label="Playbooks" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <div>
              <div>
                <div
                    className='scrol'>
                  <div>
                    <div
                        style={{ width: '100%' }}>
                      <TabPanel value={value} index={0}>
                        <Plays setLoading={setLoading}/>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <Playbooks/>
                      </TabPanel>

                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

  )
}
