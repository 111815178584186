import React from 'react'
import PropTypes from 'prop-types'
import './player.css'

function PlayerJobs ({ player, position, selection, formation, flipped }) {
  const { id, value, jobs } = player
  const imgSize = 'small'
  const imgUrl = 'assets/circle-outline.svg'
  return (
      <div
          style={
            !flipped
              ? { top: `${position.top}`, left: `${position.left}`, zIndex: '2' }
              : { top: `${position.top}`, right: `${position.left}`, zIndex: '2' }
          }
          className="player"

          data-aos="zoom-in-up"
      >
        <div style={{}}>
          {formation.formation_type !== 'defense_formation'
            ? (<>

                {player.jobAssigned
                  ? (<>

                      <div style={{ width: '60px', position: 'relative', marginTop: '5%', left: '-5%' }}>
                        <div className={`player-image ${imgSize}`}>

                        </div>
                      </div>

                      {console.log('player jobs: ' + JSON.stringify(jobs))}{jobs?.map(job => (
                        <div data-aos="zoom-in-up" onClick={(e) => {
                          console.log(e)
                        }} style={{
                          position: 'absolute',
                          marginTop: '-100%',
                          marginLeft: '-100%',
                          zIndex: '-500'
                        }}>
                          <img
                              style={{
                                position: 'relative',
                                width: `${job.size}`,
                                height: `${job.size}`,
                                marginTop: `${job.offsetY}`,
                                left: `${job.offsetX}`
                              }}
                              className={`player-image ${imgSize} dowmpic`}
                              src={`${window.location.origin}/${job.imgUrl}`} alt=""
                          />
                        </div>
                      ))}

                    </>)
                  : ''}

              </>)
            : (<>
                {player.jobAssigned
                  ? (<>
                      <div style={{ width: '60px', position: 'relative', marginTop: '5%', left: '-5%' }}>
                        <div className={`player-image ${imgSize}`}>

                        </div>
                      </div>

                      {jobs.map(job => (
                          <div data-aos="zoom-in-up"
                               style={{ position: 'absolute', marginTop: '-100%', marginLeft: '-100%' }}>
                            <img
                                style={{
                                  position: 'relative',
                                  width: `${job.size}`,
                                  height: `${job.size}`,
                                  marginTop: `${job.offsetY}`,
                                  left: `${job.offsetX}`
                                }}
                                className={`player-image ${imgSize} downpic`}
                                src={`${window.location.origin}/${job.imgUrl}`} alt=""
                            />
                          </div>
                      ))}

                    </>)
                  : ''}
              </>)

          }</div>

      </div>
  )
}

PlayerJobs.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.string.isRequired,
    jobAssigned: PropTypes.string,
    jobs: PropTypes.arrayOf(PropTypes.shape({
      offsetX: PropTypes.number.isRequired,
      offsetY: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
      imgUrl: PropTypes.string.isRequired
    }))
  }),
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  // selection,
  formation: PropTypes.string.isRequired,
  flipped: PropTypes.bool.isRequired
}

export default PlayerJobs
