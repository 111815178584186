import React from 'react'
import './navbar.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import PropTypes from 'prop-types'
// import { PlaybookContext } from '../../PlaybookContextProvider'
const Navbar = ({ strategies, active, navSelection }) => {
  React.useEffect(() => {
    Aos.init({ duration: 1500 })
    return () => {

    }
  }, [])
  return (
      <div data-aos="flip-up" className="strategies-nav">
        <ul className="strategies-nav-list">
          {strategies.map((strategy) => {
            if (strategy.title === active) {
              return <li key={strategy.id} className="strategy-nav-active">{strategy.title}</li>
            } else {
              return (<li key={strategy.id} onClick={() => navSelection(strategy.id)}>{strategy.title}</li>)
            }
          })}
        </ul>
      </div>
  )
}

Navbar.propTypes = {
  strategies: PropTypes.any.isRequired,
  active: PropTypes.any.isRequired,
  navSelection: PropTypes.any.isRequired
}

export default Navbar
