import React, { useEffect, useState } from 'react'
import './makeplaybook.css'
import { DialogActions, DialogContent, TextField } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

function RenamePlayDialog (props) {
  const [playName, setPlayName] = useState(props?.play?.name ?? '')

  const handleClose = () => {
    props.setTrigger(false)
  }

  useEffect(() => {
    if (props.play) {
      props.play.name = playName
    }
  }, [playName])

  const handleNameChange = (e) => {
    setPlayName(e.target.value)
  }
  const save = () => {
    props.play.name = playName
    props.renamePlay(props.play)
    props.setTrigger(false)
  }

  return (
      <Dialog open={props.trigger} onClose={handleClose}>
        <DialogTitle>Rename Play</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a new name for this play.
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="string"
              fullWidth
              variant="standard"
              onChange={handleNameChange}
              sx={{ mb: 3, width: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
  )
}

RenamePlayDialog.propTypes = {
  play: PropTypes.any.isRequired,
  renamePlay: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired
}

export default RenamePlayDialog
