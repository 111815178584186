import React, { useEffect, useState } from 'react'
import Navbar from './strategies-navbar/navbar'
import './strategies.css'
import axios from 'axios'
import Aos from 'aos'
import 'aos/dist/aos.css'
// import jobsData from "./jobs";
import { Icon } from '@iconify/react'
import { PlaybookContext } from '../PlaybookContextProvider'
import PropTypes from 'prop-types'

const SpecialTeamStrategies = ({ changeJob, players, selective, setPlayers, selectedPlayerName }) => {
  const { state, dispatch } = React.useContext(PlaybookContext)
  const [jobsData, seetJobsData] = useState()
  const [data, setdata] = useState()
  const [selectiveNav, setSelectiveNav] = useState()
  useEffect(() => {
    Aos.init({ duration: 800 })

    async function fetchJobsData () {
      await axios.get('/v1/webapi/playeditor_jobs').then((response) => {
        seetJobsData(response.data)
        const jobs = response.data.formation.filter(data => data.id === 9 || data.id === 5 || data.id === 1)
        setdata(jobs)
        setSelectiveNav(jobs[0])
      }).catch(error => {
        //  console.log(error);
      })
    }

    fetchJobsData()

    return () => {
    }
  }, [])

  const navSelection = (id) => {
    setSelectiveNav(data.filter(data => data.id === id)[0])
  }

  const deletejob = (job) => {
    const extplayers = [...players]
    extplayers.map((player) => {
      if (player.id === selective) {
        player.jobs = player.jobs.filter(data => (data !== job))
        if (player.jobs.length === 0) {
          player.jobAssigned = false
        }
      }
    })
    setPlayers(extplayers)
  }

  return (
      <>
        {data && selectiveNav ? (
            <div className="strategies">
              <h2>CHOOSE JOB FOR PLAYER: {selectedPlayerName}</h2>
              <div className='displayjobs'>
                {players.map((player) => {
                  if (!player.jobs) {
                    player.jobs = []
                  }
                  if (player.id === selective) {
                    return (
                      player.jobs.map((job) => (
                            <div data-aos="fade-left" className='multiplejobs'>{job.job}
                              <Icon className='remove' onClick={() => {
                                deletejob(job)
                              }} icon="feather:delete"/></div>
                      ))

                    )
                  }
                  // console.log('ok',players)
                })}
              </div>

              <Navbar strategies={data} navSelection={navSelection} active={selectiveNav.title}/>
              <div className="move">
                {selectiveNav.items.map((item) => (
                    <div
                        key={item.id}

                        className="move-item"
                        onClick={() => changeJob(item)}
                        style={{ width: item.buttonSize, height: 'auto' }}
                    >
                      <img style={{ width: item.buttonSize, height: 'auto' }}
                           src={`${window.location.origin}/${item.imageUrl}`}/>
                      <br/>
                      <h6>{item.name}</h6>
                    </div>
                ))}
              </div>
            </div>

        ) : ''}</>

  )
}

SpecialTeamStrategies.propTypes = {
  changeJob: PropTypes.any.isRequired,
  players: PropTypes.any.isRequired,
  selective: PropTypes.any.isRequired,
  setPlayers: PropTypes.any.isRequired,
  selectedPlayerName: PropTypes.any.isRequired
}

export default SpecialTeamStrategies
