import React, { useEffect, useState } from 'react'
import Position from './position/Position'
import OffenseStrategies from './strategies/OffenseStrategies'
import './step4.css'
import { Icon } from '@iconify/react'
import { useHistory, withRouter } from 'react-router-dom'
import axios from 'axios'

import 'aos/dist/aos.css'
import { PlaybookContext } from './PlaybookContextProvider'
import DefenseStrategies from './strategies/DefenseStrategies'
import SpecialTeamStrategies from './strategies/SpecialTeamStrategies'
import PropTypes from 'prop-types'

const AssignJobs = ({ play, jobs, onSetPlayers }) => {
  const [players, setPlayers] = useState([])
  const [selective, setSelective] = useState('')
  const [selectedPlayerName, setSelectedPlayerName] = useState('')
  const [formation, setFormation] = useState('')
  const {
    state,
    dispatch
  } = React.useContext(PlaybookContext)
  const [flipped, setFlipped] = useState(false)
  const [playName, setPlayName] = useState(play.name ?? `${play?.play_value}:${play?.formation_value}`)

  const [offenseFormationData, setOffenseFormationData] = useState()
  const [defenseFormationData, setDefenseFormationData] = useState()
  const [specialTeamsFormationData, setSpecialTeamsFormationData] = useState()

  const [sideNavBar, setSideNavBar] = useState(true)

  useEffect(() => {
    play.name = playName
  }, [playName])

  const process = (fet) => {
    if (play.IsMirrored === 'true' || play.IsMirrored === true) {
      // console.log('true')
      setFlipped(true)
      mirror()
    }

    // let currentFormation = null
    console.log('STATE:')
    const currentFormation = fet.formations?.filter(
      (formation) => formation.value === play.formation_value
    )

    // TODO: TIMO: This seems redundant.
    // switch (play.play_type) {
    //     case "defense_play":
    //         currentFormation = fet.formations?.filter(
    //             (formation) => formation.value === play.formation_value
    //         );
    //         break;
    //     case "special_team_play":
    //         currentFormation = fet.formations?.filter(
    //             (formation) => formation.value === play.formation_value
    //         );
    //         break;
    //     default:
    //         currentFormation = fet.formations?.filter(
    //             (formation) => formation.value === play.formation_value
    //         );
    //         break;
    // }

    if (currentFormation.length === 0) {
      alert('TIMO: Could not find formation.')
    }

    if (currentFormation.length > 1) {
      alert('TIMO: Found more than one matching formation.')
    }

    console.log('current formation', currentFormation)

    // TODO: This should be changed later once we match by ID.
    const formationPlayers = currentFormation[0].players
    let ii = 0
    formationPlayers.map((player) => {
      ii++
      if (!player.jobs) {
        player.id = 'p' + ii
      }
      player.jobs = []
    })

    console.log('formation players', formationPlayers, jobs)
    setPlayers(formationPlayers)
    onSetPlayers(formationPlayers)
    if (jobs) {
      // console.log('current formation1', currentFormation, jobs.length, jobs)
      if (jobs.length === 11) {
        // console.log('current formation2', currentFormation)
        const j = jobs
        // console.log('j', j)
        const extplayers = formationPlayers

        for (let i = 0; i < j.length; i++) {
          extplayers[i].imgSize = 'big'
          extplayers[i].jobAssigned = true
          extplayers[i].jobs = (j[i])
        }
        // console.log('j', j)
        // console.log('e', extplayers)
        // setPlayers(extplayers)
        // setPlayers(jobs);
      }
      // TODO: TIMO: Why is this being set again?!?
      // else {
      //     let currentFormation = null;
      //     switch (play.play_type) {
      //         case "defense_play":
      //             currentFormation = fet.formations?.filter(
      //                 (formation) => formation.value === play.formation_value
      //             )[0].players;
      //             break;
      //         case "special_team_play":
      //             currentFormation = fet.formations?.filter(
      //                 (formation) => formation.value === play.formation_value
      //             )[0].players;
      //             break;
      //         default:
      //             currentFormation = fet.formations?.filter(
      //                 (formation) => formation.value === play.formation_value
      //             )[0].players;
      //             break;
      //     }
      // }
    }

    if (play.formation_value !== '' && play.play_value !== '') {
      let title = ''
      switch (play.play_type) {
        case 'defense_play':
          title = 'DEFENSE'
          break
        case 'special_team_play':
          title = 'SPECIAL TEAM'
          break
        case 'clock_management':
          title = 'CLOCK MANAGMENT'
          break
        default:
          title = 'OFFENSE'
          break
      }

      setFormation({
        formation_type: play.formation_type,
        formation_name: play.formation_value,
        formation_play: play.play_value,
        formation_title: title
      })
    }
  }

  async function fetchdata1 (fet) {
    await axios.get('/v1/webapi/playeditor_formations?formation_type=offense_formation').then((response) => {
      setOffenseFormationData(response.data)
      process(response.data)
      fet = response.data
    }).catch(error => {
      console.log(error)
    })

    return fet
  }

  async function fetchdata2 (fet) {
    await axios.get('/v1/webapi/playeditor_formations?formation_type=defense_formation').then((response) => {
      setDefenseFormationData(response.data)
      process(response.data)
      fet = response.data
    }).catch(error => {
      console.log(error)
    })

    return fet
  }

  async function fetchdata3 (fet) {
    await axios.get('/v1/webapi/playeditor_formations?formation_type=special_formation').then((response) => {
      setSpecialTeamsFormationData(response.data)
      fet = response.data
      process(response.data)
    }).catch(error => {
      console.log(error)
    })

    return fet
  }

  useEffect(() => {
    console.log('miror', state)
    //  if(play.ismirrored){

    //    setismirrored(play.ismirrored)
    //  }
    if (play.play_type === 'defense_play') {
      fetchdata2().then((fet) => {
        // console.log(fet)

      })
      // console.log(fet)
    } else if (play.play_type === 'special_team_play') {
      fetchdata3().then((fet) => {
        // console.log(fet)

      })
      // console.log(fet)
    } else if (play.play_type === 'offense_play') {
      fetchdata1().then((fet) => {
        // console.log(fet)

      })
    }
  }, [])

  // console.log("state", state);

  const history = useHistory()
  const selection = (id) => {
    if (id) {
      setSelective(id)
      // console.log(id)
      const selectedName = players.filter((player) => player.id === id)[0]
      // console.log('sel', selectedName);
      // console.log('selectinve', selective)
      players.map((player) => {
        if (player === selectedName) {
          player.selected = true
        } else {
          player.selected = false
        }
        return player
      })
      setSelectedPlayerName(selectedName.value)
    } else {
      setSelective('')
    }
  }
  const done = () => {
    if (players.filter((player) => player.jobAssigned === true).length === 11) {
      console.log('playes', players)
      dispatch({
        type: 'step4',
        payload: {
          jobs: [...players],
          IsMirrored: ismirrored,
          name: playName
        }
      })

      history.push('/saveplay')
    }
  }
  const [ismirrored, setismirrored] = useState(false)

  const mirror = () => {
    if (!ismirrored) {
      document.getElementById('mirror').style.background = 'white'
    } else {
      document.getElementById('mirror').style.background = null
    }
    setismirrored(!ismirrored)
    // console.log(ismirrored)
  }
  const changeJob = (job) => {
    const extplayers = [...players]
    extplayers.map((extplayer) => {
      // console.log('sssssss', extplayer,job)
      if (extplayer.value === 'QB') {
        if (extplayer.id === selective && extplayer.jobs.length < 6) {
          const j = {
            job: job.value,
            size: job.size,
            offsetX: job.offsetX,
            offsetY: job.offsetY,
            imgUrl: job.imageUrl
          }

          if (extplayer.jobs.filter(job => (job.job === j.job)).length === 0) {
            extplayer.imgSize = 'big'
            extplayer.jobs.push(j)
            extplayer.jobAssigned = true
            if (extplayer.jobs.length === 6) {
              extplayer.ready = true
              // s
            }
          } else {

            // console.log('k', extplayer.jobs.filter(job => (job === j))[0])
          }
        }
      } else {
        if (extplayer.id === selective && extplayer.jobs.length < 4) {
          const j = {
            job: job.value,
            size: job.size,
            offsetX: job.offsetX,
            offsetY: job.offsetY,
            imgUrl: job.imageUrl
          }

          if (extplayer.jobs.filter(job => (job.job === j.job)).length === 0) {
            extplayer.imgSize = 'big'
            extplayer.jobs.push(j)
            extplayer.jobAssigned = true
            if (extplayer.jobs.length === 6) {
              extplayer.ready = true
              // s
            }
          } else {

            // console.log('k', extplayer.jobs.filter(job => (job === j))[0])
          }
        }
      }

      return extplayer
    })
    setPlayers(extplayers)
    onSetPlayers(extplayers)

    // console.log('exta', extplayers)
  }

  const cancel = () => {
    dispatch({
      type: 'step5'
    })
    history.push('/')
  }
  const onflipped = () => {
    mirror()
    flipped ? setFlipped(false) : setFlipped(true)
  }
  let intervalid2 = ''
  let intervalid = ''
  const reset = () => {
    const extplayers = [...players]
    extplayers.map((extplayer) => {
      const btn = document.getElementById('reset')
      btn.style.WebkitTransitionDuration = '1s'
      btn.style.transform = 'rotate(-180deg)'
      btn.style.color = 'green'
      intervalid2 = setInterval(animebtnreset, 800)
      extplayer.jobAssigned = false
      extplayer.jobs = []
      extplayer.jobNumber = 0
      extplayer.selected = false
      extplayer.ready = false

      return extplayer
    })
    setPlayers(extplayers)
    onSetPlayers(extplayers)

    setSelective('')
  }
  const undo = () => {
    const extplayers = [...players]

    extplayers.map((extplayer) => {
      if (extplayer.id === selective) {
        extplayer.jobAssigned = false
        extplayer.jobs = []
        const btn = document.getElementById('undo')
        btn.style.WebkitTransitionDuration = '1s'
        btn.style.transform = 'rotate(-180deg)'
        btn.style.color = 'green'
        intervalid = setInterval(animebtn, 100)
      }

      return extplayer
    })
    setPlayers(extplayers)
    onSetPlayers(extplayers)
    setSelective('')
  }
  let btnval = 1.0
  const animebtn = () => {
    const btn = document.getElementById('undo')
    btnval = btnval - 0.1

    if (btnval < 0.0) {
      btnval = 1.0
      // btn.style.WebkitTransitionDuration='1s'
      btn.style.transform = 'rotate(100deg)'
      btn.style.color = 'black'
      // console.log(intervalid)
      clearInterval(intervalid)
    }
  }
  let btnrest = 1.0
  const animebtnreset = () => {
    btnrest = btnrest - 0.1
    // console.log(btnrest)
    if (btnrest < 0.0) {
      btnrest = 1.0
      const btn = document.getElementById('reset')

      // console.log(intervalid2)
      clearInterval(intervalid2)
      for (let i = 1; i < intervalid2 * 2; i++) {
        clearInterval(i)
      }
      btn.style.WebkitTransitionDuration = '1s'
      btn.style.transform = 'rotate(90deg)'
      btn.style.color = 'black'
    }
  }
  return (

      <PlaybookContext.Consumer>
        {(value) => (
            <>

              <div
                  className="run-play"
                  data-aos="fade">
                <div
                    className="popup4">
                  <div
                      className="headersetting"
                      data-aos="zoom-in">
                    <div
                        className="headerset1">
                      <Icon
                          icon="radix-icons:exit"
                          rotate={2}
                          onClick={cancel}/>
                      {/* <Icon icon="ant-design:download-outlined" /> */}
                      {/* <Icon icon="uiw:setting" rotate={2} /> */}
                      {/* <Icon icon="ci:help-circle-outline" /> */}
                    </div>

                    <div
                        className="headerset2">
                      <input
                          style={{ width: '100%' }}
                          name='name'
                          value={playName}
                          onChange={(event) => {
                            setPlayName(event.target.value)
                            play.name = event.target.value
                          }}></input>
                      {/* <p>
                    {formation.formation_play}: {formation.formation_name}
                  </p> */}
                      {/* <Icon icon="fa-solid:pen" style={{ color: "gray" }} /> */}
                    </div>

                    <div
                        className="headerset3"
                        id='mirror'
                        onClick={() => onflipped()}>
                      {/* <Icon icon="ci:undo" /> */}
                      {/* <Icon icon="ci:redo" /> */}
                      <Icon
                          icon="akar-icons:arrow-up-down"
                          rotate={1}

                      />
                    </div>
                    <div
                        className="reset"
                        onClick={undo}>
                      <Icon
                          id='undo'
                          icon="ant-design:undo-outlined"
                          width="30"
                          rotate={1}/>
                    </div>
                    <div
                        className="reset"
                        onClick={reset}>
                      <Icon
                          id='reset'
                          icon="bx:bx-reset"
                          width="30"/>

                    </div>
                    <div
                        className="buttons"
                        onClick={() => setSideNavBar(!sideNavBar)}>
                      <button
                          style={!sideNavBar ? { background: 'gray' } : {}}
                          className="done">
                        Sidebar
                      </button>
                    </div>
                  </div>
                  <div
                      className="mainpopup4">
                    <Position
                        selection={selection}
                        players={players}
                        history={history}
                        selective={selective}
                        background={play.background}
                        playValue={play.play_value}
                        formation={formation}
                        flipped={flipped}
                    />
                    {sideNavBar
                      ? (<>
                          {play.play_type === 'defense_play'
                            ? (
                                  <DefenseStrategies
                                      changeJob={changeJob}
                                      players={players}
                                      selective={selective}
                                      setPlayers={setPlayers}
                                      selectedPlayerName={selectedPlayerName}
                                  />
                              )
                            : play.play_type === 'special_team_play'
                              ? (
                                      <SpecialTeamStrategies
                                          changeJob={changeJob}
                                          players={players}
                                          selective={selective}
                                          setPlayers={setPlayers}
                                          selectedPlayerName={selectedPlayerName}
                                      />
                                )
                              : (
                                      <OffenseStrategies
                                          changeJob={changeJob}
                                          players={players}
                                          selective={selective}
                                          setPlayers={setPlayers}

                                          selectedPlayerName={selectedPlayerName}
                                      />
                                )}
                        </>)
                      : ''}

                  </div>
                </div>
              </div>

            </>
        )}
      </PlaybookContext.Consumer>
  )
}

AssignJobs.propTypes = {
  play: PropTypes.any.isRequired,
  jobs: PropTypes.any.isRequired,
  onSetPlayers: PropTypes.any.isRequired
}

export default withRouter(AssignJobs)
