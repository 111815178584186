import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

export const PlaybookContext = createContext()

let initialState = {}
if (localStorage.getItem('step')) {
  initialState = {
    background: localStorage.getItem('background'),
    formation_type: localStorage.getItem('formation_type'),
    formation_value: localStorage.getItem('formation_value'),
    step: 0,
    IsMirrored: localStorage.getItem('IsMirrored'),
    editing: localStorage.getItem('editing'),
    play_type: localStorage.getItem('play_type'),
    play_value: localStorage.getItem('play_value'),
    name: localStorage.getItem('name'),
    jobs: JSON.parse(localStorage.getItem('jobs')),
    playId: localStorage.getItem('playId')
  }
} else {
  initialState = {
    background: '',
    play_value: '',
    play_type: '',
    formation_type: '',
    formation_value: '',
    step: 0,
    jobs: [],
    name: '',
    editing: false,
    IsMirrored: false,
    playId: ''
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'step1':
      localStorage.setItem('step', 1)
      localStorage.setItem('background', action.payload.background)
      return { ...state, background: action.payload.background, step: 1 }
    case 'step2':
      localStorage.setItem('step', 2)
      localStorage.setItem('play_type', action.payload.play_type)
      localStorage.setItem('play_value', action.payload.play_value)
      return {
        ...state,
        play_type: action.payload.play_type,
        play_value: action.payload.play_value,
        step: 2
      }
    case 'step3':
      localStorage.setItem('step', 3)
      localStorage.setItem('formation_type', action.payload.formation_type)
      localStorage.setItem('formation_value', action.payload.formation_value)
      localStorage.setItem('IsMirrored', false)
      localStorage.setItem('name', action.payload.name)
      return {
        ...state,
        formation_value: action.payload.formation_value,
        formation_type: action.payload.formation_type,
        name: action.payload.name,
        IsMirrored: false,
        step: 3
      }
    case 'step4':
      localStorage.setItem('step', 4)
      localStorage.setItem('jobs', JSON.stringify(action.payload.jobs))
      localStorage.setItem('IsMirrored', action.payload.IsMirrored)
      localStorage.setItem('name', action.payload.name)
      return {
        ...state,
        jobs: (action.payload.jobs),
        IsMirrored: action.payload.IsMirrored,
        name: action.payload.name,
        step: 4
      }
    case 'edit':
      localStorage.setItem('editing', true)
      localStorage.setItem('jobs', JSON.stringify(action.payload.jobs))
      localStorage.setItem('IsMirrored', action.payload.IsMirrored)
      localStorage.setItem('name', action.payload.name)
      // TODO: TIMO: add formation_id
      localStorage.setItem('formation_type', action.payload.formation_type)
      localStorage.setItem('formation_value', action.payload.formation_value)
      localStorage.setItem('IsMirrored', action.payload.IsMirrored)
      localStorage.setItem('name', action.payload.name)
      localStorage.setItem('play_type', action.payload.play_type)
      localStorage.setItem('play_value', action.payload.play_value)
      localStorage.setItem('background', action.payload.background)
      localStorage.setItem('playId', action.payload.playId)
      localStorage.setItem('step', 4)
      return {
        ...state,
        background: action.payload.background,
        play_type: action.payload.play_type,
        play_value: action.payload.play_value,
        formation_value: action.payload.formation_value,
        formation_type: action.payload.formation_type,
        IsMirrored: action.payload.IsMirrored,
        name: action.payload.name,
        jobs: (action.payload.jobs),
        playId: action.payload.playId,
        editing: true,
        step: 4
      }

    case 'step5':
      localStorage.clear()
      return {
        state: initialState
      }
    default:
      return state
  }
}

function PlaybookContextProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState, undefined)
  const value = { state, dispatch }
  return (
      <PlaybookContext.Provider value={value}>
        {children}
      </PlaybookContext.Provider>
  )
}

PlaybookContextProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export default PlaybookContextProvider
