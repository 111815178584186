import React from 'react'
import ReactDOM from 'react-dom/client'
import PlaybookContextProvider from './components/PlaybookContextProvider'
import App from './app.js'
import { BrowserRouter } from 'react-router-dom'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(
    <BrowserRouter>
        <PlaybookContextProvider>
            <App/>
        </PlaybookContextProvider>
    </BrowserRouter>
)
