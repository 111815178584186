import React from 'react'
import './player.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const PlayerOpposition = ({ player, position, flipped, formation }) => {
  const { id, value, jobs } = player
  const imgSize = 'small'
  const imgUrl = 'assets/circle-outline.svg'
  {
    console.log('a', player)
  }
  React.useEffect(() => {
    Aos.init({ duration: 800 })
    return () => {

    }
  }, [])

  return (
      <div
          style={
            !flipped
              ? { top: `${position.top}`, left: `${position.left}`, zIndex: '1' }
              : { top: `${position.top}`, right: `${position.left}`, zIndex: '1' }
          }
          className="player"
          data-aos="zoom-in-up"
      >
        {formation.formation_type !== 'defense_formation'
          ? (<>
              <div style={{ marginTop: '-20px' }}><img
                  style={{ opacity: '1', marginTop: '10%', left: '-5%' }}
                  className={`player-image ${'small'}`}
                  src={`${window.location.origin}/${'assets/opposition-defense.png'}`}
              />

                <div style={{ cursor: 'pointer', marginTop: '-15px' }} className="player-name-defense">
                  {(value.length > 1)
                    ? (
                          <h2 style={{ marginLeft: '-25%' }}>
                            {value}
                          </h2>
                      )
                    : (
                          <h2>
                            {value}
                          </h2>
                      )}

                </div>
              </div>
            </>)
          : (<>
              <div style={{ marginTop: '5px' }}><img

                  style={{ opacity: '1', marginTop: '10%', left: '-5%' }}
                  className={`player-image ${'small'}`}
                  src={`${window.location.origin}/${'assets/opposition-offense.png'}`}
              />
                <div style={{ cursor: 'pointer' }} className="player-name">
                  {(value.length > 1)
                    ? (
                          <h2 style={{ marginLeft: '-25%' }}>
                            {value}
                          </h2>
                      )
                    : (
                          <h2>
                            {value}
                          </h2>
                      )}

                </div>
              </div>
            </>)}

      </div>

  )
}

export default PlayerOpposition
