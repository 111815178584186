import React, { useEffect, useState } from 'react'
import './view.css'
import axios from 'axios'
// import JOBS_DATA from "./strategies/jobs";
import { Icon } from '@iconify/react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { PlaybookContext } from './PlaybookContextProvider'
import { useHistory, useLocation } from 'react-router-dom'
// import Position from './position/position'
const playurl = '/v1/webapi/playbook_entries'
const playbookurl = '/v1/webapi/playbook_schemes'

// const playcallsheeturl = '/v1/webapi/playcall_sheets'
function ViewPage () {
  const history = useHistory()
  const location = useLocation()
  const [selectedplay, setselectedplay] = useState('')
  const [JOBS_DATA, setJOBS_DATA] = useState()
  const [playbookid, setplaybookid] = useState('')
  const [playtype, setplaytype] = useState('')
  const [playoffense, setPlayoffense] = useState('')
  const [playdefense, setPlaydefense] = useState('')
  const [playspecial, setPlayspecial] = useState('')
  const [selectedPlayId, setSelectedPlayId] = useState('')
  const [playdata, setplaydata] = useState('')
  const [play, setPlay] = useState('')
  const { state, dispatch } = React.useContext(PlaybookContext)
  const [data, setdata] = useState()
  useEffect(() => {
    // console.log(location)

    Aos.init({ duration: 800 })
    setplaybookid(location.state.id)

    async function fetchjobsdata () {
      await axios.get('/v1/webapi/playeditor_jobs').then((response) => {
        setJOBS_DATA(response.data)
        console.log('res', response.data)
        setdata(response.data.formation)
      }).catch(error => {
        console.log(error)
      })
    }

    fetchjobsdata()
    axios.get(playurl).then((response) => {
      console.log(response.data.length)
      setplaydata(response.data)
    }).catch(error => {
      console.log(error)
    })

    axios.get(playbookurl + '/' + location.state.id).then((response) => {
      // console.log(response.data);
      if (response.data.length === 0) {
        // console.log('sk')
      } else {
        if (response.data.offense) {
          //    console.log('o',response.data['offense'])
          setPlayoffense(response.data.offense)
        }
        if (response.data.defense) {
          // console.log('d',response.data['defense'])
          setPlaydefense(response.data.defense)
        }
        if (response.data.special) {
          // console.log('sp',response.data['special'])
          setPlayspecial(response.data.special)
        }
      }
    }).catch(error => {
      console.log(error)
    })
  }, [])
  const sprate = (p) => {
    // console.log(p)
    p = p.split('_')
    return p
  }

  const offenseclick = (book) => {
    console.log('b', book)
    setselectedplay(book)
    setplaytype('offense')
    setSelectedPlayId(book.playbookEntryIDs)
  }
  const defenseclick = (book) => {
    // console.log( 'b',book)
    setselectedplay(book)
    setplaytype('defense')
    setSelectedPlayId(book.playbookEntryIDs)
  }
  const specialclick = (book) => {
    // console.log( 'b',book)
    setselectedplay(book)
    setplaytype('special')
    setSelectedPlayId(book.playbookEntryIDs)
  }
  const remove = () => {
    if (playtype === 'offense') {
      let data = playoffense
      data = data.filter(data => data !== selectedplay)
      setPlayoffense(data)
      const d = { offense: data }
      axios.put(playbookurl + '/' + location.state.id, d).then((response) => {
        // console.log(response.data);
      }).catch(error => {
        console.log(error)
      })
    }
    if (playtype === 'defense') {
      let data = playdefense
      data = data.filter(data => data !== selectedplay)
      setPlaydefense(data)
      const d = { defense: data }
      axios.put(playbookurl + '/' + location.state.id, d).then((response) => {
        // console.log(response.data);

      }).catch(error => {
        console.log(error)
      })
    }
    if (playtype === 'special') {
      let data = playspecial
      data = data.filter(data => data !== selectedplay)
      setPlayspecial(data)
      const d = { special: data }
      axios.put(playbookurl + '/' + location.state.id, d).then((response) => {
        // console.log(response.data);

      }).catch(error => {
        console.log(error)
      })
    }
    setselectedplay()
    setplaytype()
    setSelectedPlayId()
  }
  const edit = () => {
    // console.log('edith')
    if (selectedPlayId.length > 0) {
      axios.get(playurl + '/' + selectedPlayId).then((response) => {
        console.log('res', response.data)
        setPlay(response.data)
        editing(response.data)
      }).catch(error => {
        console.log(error)
      })
    }
  }
  const openpage = () => {
    if (selectedPlayId.length > 0) {
      const u = playurl + '/' + selectedPlayId
      const newWindow = window.open(u, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
  const editing = (play) => {
    console.log('p', play)

    if (play) {
      // const jobValues = play.jobs
      console.log('jobss1', play)

      const dd = data

      const jobs = []
      const jobsData = []
      for (let d = 0; d < dd.length; d++) {
        for (let e = 0; e < dd[d].items.length; e++) {
          jobsData.push(dd[d].items[e])
        }
      }
      // console.log(jobs_data[0])

      for (let j = 0; j < play.jobs.length; j++) {
        const jobarr = play.jobs[j].possibleJobs
        const jobparts = []
        console.log('jobss1', jobarr)
        for (let i = 0; i < jobarr.length; i++) {
          const nm = jobarr[i].job
          const jd = jobsData.filter(jd => jd.value === nm)
          console.log('i', jd, jobarr[i].job, nm)
          jobparts.push({
            job: jd[0].value,
            size: jd[0].size,
            offsetX: jd[0].offsetX,
            offsetY: jd[0].offsetY,
            imgUrl: jd[0].imageUrl
          })
        }
        console.log('lobpart', jobparts, j)

        jobs.push(jobparts)
      }

      console.log('jobs', jobs, play.jobs)
      dispatch({
        type: 'edit',
        payload: {
          background: play.background,
          formation_value: play.formation_value,
          formation_type: play.formation_type,
          IsMirrored: play.IsMirrored,
          name: play.name,
          play_type: play.play_type,
          play_value: play.play_value,
          playId: play._id,
          jobs

        }
      })
      history.push('/step4')
    }
  }

  function getname (id) {
    if (playdata) {
      const p = playdata.filter(playdata => playdata._id === id)[0]
      if (p) {
        return p.name
      }
    }
  }

  console.log('o', playoffense, playdefense, playspecial)
  return (
      <div className='popup'>
        <div className='popupcontent3'>
          <div className="popup3">
            <div data-aos="fade-left" className='headersetting'>
              <div data-aos="fade-left" className='headerset1'>
                <Icon icon="radix-icons:exit" rotate={2}
                      onClick={() =>
                        history.push('/')}/>
                {/* <Icon icon="ant-design:download-outlined"  /> */}
                {/* <Icon icon="uiw:setting" rotate={2} /> */}
                {/* <Icon icon="ci:help-circle-outline"  /> */}
              </div>

              <div data-aos="fade-left" className='headerset2'>
                {selectedplay
                  ? (
                        <h2><span>{sprate(selectedplay.value)[0]}: </span> {sprate(selectedplay.value)[1]}
                        </h2>)
                  : ''}

                {/* <Icon icon="fa-solid:pen" style={{color:"gray"}} /> */}
              </div>

              <div data-aos="fade-left" className='headerset3'>

                <Icon icon="akar-icons:arrow-up-down" rotate={1}/>
              </div>
              <div data-aos="fade-left" className='headersetedit'>
                <button onClick={edit}> Edit</button>
              </div>
              <div data-aos="fade-left" className='headerset4'>
                <button onClick={remove}> Remove</button>

              </div>
              <div data-aos="fade-left" className='headersetjson'>
                <button onClick={openpage}>JSON DATA</button>

              </div>
              <div data-aos="fade-left" className="headerset5">
                <button onClick={() => {
                  history.push('/')
                }}> Home
                </button>
              </div>

            </div>
            <div>
              <div className='mainpopup3'>
                <div className='scrol'>
                  <div className='popup3row'>
                    {playoffense
                      ? (<div style={{ width: '100%' }}>
                          <div className='play1'>
                            {playoffense.map((playoffense) => (
                                <div key={playoffense.playbookEntryIDs} data-aos="fade-up"
                                     className="created_playlist" id={playoffense.playbookEntryIDs}
                                     onClick={() => {
                                       offenseclick(playoffense)
                                     }}>
                                  <div className="headplayview">
                                    {/* <h2><span>{sprate(playoffense.value)[0]}: </span> {sprate(playoffense.value)[1]}</h2> */}
                                    <h2>{getname(playoffense.playbookEntryIDs)}</h2>
                                  </div>
                                </div>
                            ))
                            }</div>
                        </div>)
                      : ''
                    }

                    {playdefense ? (<div style={{ width: '100%' }}>
                      <div className='play1'>
                        {playdefense.map((playdefense) => (
                            <div key={playdefense.playbookEntryIDs} data-aos="fade-up"
                                 className="created_playlist" id={playdefense.playbookEntryIDs}
                                 onClick={() => {
                                   defenseclick(playdefense)
                                 }}>
                              <div className="headplayview">
                                {/* <h2><span>{sprate(playdefense.value)[0]}: </span> {sprate(playdefense.value)[1]}</h2> */}
                                <h2>{getname(playdefense.playbookEntryIDs)}</h2>
                              </div>
                            </div>
                        ))
                        }</div>
                    </div>) : ''}
                    {playspecial ? (<div style={{ width: '100%' }}>

                      <div className='play1'>
                        {/* {console.log( 'pls',playspecial)} */}

                        {playspecial.map((playspecial) => (

                            <div key={playspecial.playbookEntryIDs} data-aos="fade-up"
                                 className="created_playlist" id={playspecial.playbookEntryIDs}
                                 onClick={() => {
                                   specialclick(playspecial)
                                 }}>

                              <div className="headplayview">

                                {/* <h2><span>{sprate(playspecial.value)[0]}: </span> {sprate(playspecial.value)[1]}</h2> */}
                                <h2>{getname(playspecial.playbookEntryIDs)}</h2>
                              </div>

                            </div>

                        ))
                        }</div>
                    </div>) : ''} </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

  )
}

export default ViewPage
