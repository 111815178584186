import React, { useState } from 'react'
import axios from 'axios'
import './makeplaybook.css'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

const teamsUrl = '/v1/webapi/teams'

function MakePlaybookDialog (props) {
  const [teamsData, setTeamsData] = useState()
  const [selectedTeam, setSelectedTeam] = useState()
  const [playbookName, setPlaybookName] = useState('')
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState('')

  React.useEffect(() => {
    axios
      .get(teamsUrl)
      .then((response) => {
        if (response.data.length === 0) {
          console.log('sk')
        } else {
          setTeamsData(response.data)
          setSelectedTeam(response.data[0]._id)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const handlePlaybookNameChanged = (e) => {
    if (e.target.value) {
      setError(false)
      setErrorText('')
    }
    setPlaybookName(e.target.value)
  }

  const handleTeamSelectionChanged = (e) => {
    setSelectedTeam(e.target.value)
  }

  const cancel = () => {
    props.setTrigger(false)
  }

  const save = () => {
    const playbook = {}
    const trimmedName = playbookName?.trim()
    if (!trimmedName) {
      setError(true)
      setErrorText('Playbook name cannot be empty')
      return
    }
    if (trimmedName && selectedTeam) {
      const team = teamsData.find(team => team._id === selectedTeam)
      playbook.playbookname = trimmedName
      playbook.TeamName = team.TeamName
      playbook.teamID = team._id
      props.makePlaybook(playbook)
      props.setTrigger(false)
    }
  }

  const handleClose = () => {
    props.setTrigger(false)
  }

  if (!teamsData || (teamsData.length === 0)) {
    return ''
  }

  return (
      <Dialog open={props.trigger} onClose={handleClose}>
        <DialogTitle>Make Playbook</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for this playbook.
          </DialogContentText>

          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="string"
              fullWidth
              sx={{ mb: 3 }}
              variant="standard"
              onChange={handlePlaybookNameChanged}
              error={error}
              helperText={errorText}
          />
          <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel>Team</InputLabel>
            <Select
                label="Team"
                sx={{ mb: 3, width: 300 }}
                onChange={handleTeamSelectionChanged}
                value={selectedTeam}
            >
              {
                teamsData
                  ? (
                      teamsData.map((team) => (
                            <MenuItem key={team._id} value={team._id}>{team.TeamName}</MenuItem>
                      ))
                    )
                  : ''}

            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
  )
}

MakePlaybookDialog.propTypes = {
  makePlaybook: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired
}

export default MakePlaybookDialog
