import React, { useEffect, useState } from 'react'
import './step3.css'
import { PlaybookContext } from './PlaybookContextProvider'

import axios from 'axios'
import 'aos/dist/aos.css'
import { Pagination, Stack } from '@mui/material'

function SelectOffenseFormation (props) {
  const [formationData, setFormationData] = useState()
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(3)
  const [itemsPerPage, setItemsPerPage] = useState(15)

  const handlePageChange = (event, pageNumber) => {
    setPage(pageNumber)
  }

  useEffect(() => {
    async function fetchFormationData () {
      const selectedFormationId = props.formation?.id
      if (selectedFormationId) {
        const v = document.getElementById(selectedFormationId)
        if (v) {
          v.style.border = '5px solid'
          v.style.borderColor = 'rgb(116 164 47)'

          // reset complete status
          if (props.completeStep) {
            props.completeStep(true)
          }
        }
      } else {
        if (props.completeStep) {
          props.completeStep(false)
        }
      }

      if (formationData) {
        return
      }

      await axios.get('/v1/webapi/playeditor_formations?formation_type=offense_formation').then((response) => {
        const formations = response.data.formations
        const selectedFormationId = props.formation?.id

        setFormationData(response.data)
        setPageCount(Math.ceil(formations.length / itemsPerPage))

        if (selectedFormationId) {
          let id = null

          if (formations) {
            for (let i = 0; i < formations.length; i++) {
              if (formations[i].id === selectedFormationId) {
                id = formations[i].id
              }
              if (id) {
                const v = document.getElementById(id)
                v.style.border = '5px solid'
                v.style.borderColor = 'rgb(116 164 47)'

                // update complete status
                if (props.completeStep) {
                  props.completeStep(true)
                }
              } else {
                if (props.completeStep) {
                  props.completeStep(false)
                }
              }
            }
          }
        } else {
          if (props.completeStep) {
            props.completeStep(false)
          }
        }
      }).catch(error => {
        //  console.log(error);
      })
    }

    fetchFormationData()
  })

  const handleSelect = (id) => {
    const a = document.getElementsByClassName('picboxste3')
    for (let i = 0; i < a.length; i++) {
      a[i].style.border = ''
    }
    const v = document.getElementById(id)
    v.style.border = '5px solid'
    v.style.borderColor = 'rgb(116 164 47)'

    const formation = formationData.formations.find(formation => formation.id === id)
    if (props.setFormation) {
      props.setFormation(formation)
    }
    if (props.completeStep) {
      props.completeStep(true)
    }
    if (props.play) {
      props.play.formationId = formation.id
      props.play.formation_type = formation.formationType
      props.play.formation_value = formation.value
    }
  }

  return (
      <PlaybookContext.Consumer>
        {(value) => (
            <>
              {formationData
                ? (<>

                    <div className="step3">

                      <div className="mainstep3">

                        <div className="backgroundtitle">Select Offense Formation</div>

                        {(page === 1)
                          ? (

                                <div className="step3mainpics" id="page1">

                                  <div className="step3row">
                                    {formationData.formations.slice(0, 5).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                  <div className="step3row">
                                    {formationData.formations.slice(5, 10).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                  <div className="step3row">
                                    {formationData.formations.slice(10, 15).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                </div>

                            )
                          : ''}

                        {(page === 2)
                          ? (
                                <div className="step3mainpics" id="page2">

                                  <div className="step3row">
                                    {formationData.formations.slice(15, 20).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          {console.log(formation)}

                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                  <div className="step3row">
                                    {formationData.formations.slice(20, 25).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                  <div className="step3row">
                                    {formationData.formations.slice(25, 29).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                </div>
                            )
                          : ''}

                        {(page === 3)
                          ? (
                                <div className="step3mainpics" id="page3">

                                  <div className="step3row">
                                    {formationData.formations.slice(29, 34).map((formation) => (
                                        <div

                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >

                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}

                                  </div>
                                  <div className="step3row">
                                    {formationData.formations.slice(34, 39).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>

                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}
                                  </div>
                                  <div className="step3row">
                                    {formationData.formations.slice(29, 31).map((formation) => (
                                        <div
                                            className="picboxste3"
                                            id={formation.id}
                                            onClick={() => handleSelect(formation.id)}
                                        >
                                          <p>{formation.value}</p>
                                          <img alt="" value={formation.value} src={formation.imgUrl}/>
                                        </div>
                                    ))}

                                  </div>

                                </div>
                            )
                          : ''}

                      </div>

                      <div className="bottomdef"

                      >

                        <Stack
                            sx={{
                              width: '100%',
                              position: 'fixed',
                              bottom: 50,
                              'align-items': 'center'
                            }}
                        >
                          <Pagination count={pageCount} page={page} onChange={handlePageChange}/>
                        </Stack>

                      </div>
                    </div>

                  </>)
                : ''}
            </>

        )}
      </PlaybookContext.Consumer>
  )
}

export default SelectOffenseFormation
