import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import axios from 'axios'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useHistory } from 'react-router-dom'
import { PlaybookContext } from './PlaybookContextProvider'
import './save.css'

const playurl = '/v1/webapi/playbook_entries'
const playbookurl = '/v1/webapi/playbook_schemes'

function SavePlay (props) {
  const [playbookstate, setPlaybookSate] = useState(null)
  const [selectedbook, setSelectedBook] = useState(null)
  const { state, dispatch } = React.useContext(PlaybookContext)
  const [jobs, setjobs] = useState()
  const history = useHistory()
  console.log(localStorage)
  console.log(state.jobs)
  React.useEffect(() => {
    Aos.init({ duration: 800 })
    console.log('state', state)
    axios.get(playbookurl).then((response) => {
      console.log(response.data)
      if (response.data.length == 0) {
        console.log('sk')
      } else {
        setPlaybookSate(response.data)
      }
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const save = () => {
    let d = []
    for (let i = 0; i < state.jobs.length; i++) {
      const arr = []
      console.log('kj', state.jobs[i].jobs)
      for (let j = 0; j < state.jobs[i].jobs.length; j++) {
        arr.push({
          job: state.jobs[i].jobs[j].job
        })
      }
      const dict = {}
      const top = state.jobs[i].position.top.split('%')
      const left = state.jobs[i].position.left.split('%')
      console.log(state.jobs[i])

      dict.possibleJobs = arr
      dict.position = state.jobs[i].value
      dict.X = Number(top[0])
      dict.Y = Number(left[0])
      // dict['size']=jobs[i].size
      d.push(dict)
    }
    console.log('d', d)
    const team = playbookstate?.filter(playbookstate => playbookstate._id === selectedbook)
    const data = {
      background: state.background,
      play_value: state.play_value,
      play_type: state.play_type,
      formation_type: state.formation_type,
      formation_value: state.formation_value,
      IsMirrored: state.IsMirrored,
      jobs: d,
      name: state.name

    }
    if (state.editing) {
      axios
        .put(playurl + '/' + state.playId, data)
        .then((response) => {
          console.log(response)
          history.push('/')
        })
        .catch((error) => {
          console.log(error)
        })
    }
    console.log(data)
    if (selectedbook) {
      const team = playbookstate.filter(playbookstate => playbookstate._id === selectedbook)
      const data = {
        background: state.background,
        play_value: state.play_value,
        play_type: state.play_type,
        formation_type: state.formation_type,
        formation_value: state.formation_value,
        IsMirrored: state.IsMirrored,
        jobs: d,
        name: state.name,
        teamID: team[0].teamID
      }
      if (state.editing) {
        axios
          .put(playurl + '/' + state.playId, data)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        axios
          .post(playurl, data)
          .then((response) => {
            console.log(response)

            axios.get(playurl).then((response) => {
              console.log(response.data)
              if (response.data.length == 0) {
                console.log('sk')
              } else {
                console.log('res', response.data)
                const res = response.data
                const data = []
                const l = res.length
                console.log('ser', res[l - 1])
                console.log(selectedbook)
                const p = playbookstate.filter(playbookstate => playbookstate._id === selectedbook)
                const ln = Object.keys(p[0]).length
                let dt = {}
                if (state.formation_type === 'offense_formation') {
                  if (p[0].offense) {
                    const of = p[0].offense.length
                    d = {
                      name: res[l - 1].name,
                      description: 'This is the ' + (of + 1) + ' offensive scheme',
                      value: res[l - 1].play_value + '_' + res[l - 1].formation_value,
                      playbookEntryIDs: res[l - 1]._id
                    }
                    const pt = p[0].offense
                    pt.push(d)
                    p[0].offense.push(d)
                    dt = { offense: pt }
                  } else {
                    d = {
                      name: res[l - 1].name,
                      description: 'This is the 1 offensive scheme',
                      value: res[l - 1].play_value + '_' + res[l - 1].formation_value,
                      playbookEntryIDs: res[l - 1]._id
                    }
                    dt = { offense: [d] }
                    p[0].offense = []
                    p[0].offense.push(d)
                  }
                }
                if (state.formation_type === 'defense_formation') {
                  if (p[0].defense) {
                    const of = p[0].defense.length
                    d = {
                      name: res[l - 1].name,
                      description: 'This is the ' + (of + 1) + 'defensive scheme',
                      value: res[l - 1].play_value + '_' + res[l - 1].formation_value,
                      playbookEntryIDs: res[l - 1]._id
                    }
                    const pt = p[0].defense
                    pt.push(d)
                    p[0].defense.push(d)
                    dt = { defense: pt }
                  } else {
                    d = {
                      name: res[l - 1].name,
                      description: 'This is the 1 offensive scheme',
                      value: res[l - 1].play_value + '_' + res[l - 1].formation_value,
                      playbookEntryIDs: res[l - 1]._id
                    }
                    p[0].defense = []
                    p[0].defense.push(d)
                    dt = { defense: [d] }
                  }
                }
                if (state.formation_type === 'special_formation') {
                  if (p[0].special) {
                    const of = p[0].special.length
                    d = {
                      name: res[l - 1].name,
                      description: 'This is the ' + (of + 1) + 'special scheme',
                      value: res[l - 1].play_value + '_' + res[l - 1].formation_value,
                      playbookEntryIDs: res[l - 1]._id
                    }
                    const pt = p[0].special
                    pt.push(d)
                    p[0].special.push(d)
                    dt = { special: pt }
                  } else {
                    d = {
                      name: res[l - 1].name,
                      description: 'This is the 1 special scheme',
                      value: res[l - 1].play_value + '_' + res[l - 1].formation_value,
                      playbookEntryIDs: res[l - 1]._id
                    }
                    data.push(d)
                    p[0].special = []
                    p[0].special.push(d)
                    dt = { special: [d] }
                  }
                }

                console.log(`${playbookurl}/${selectedbook}`)
                axios
                  .put(`${playbookurl}/${selectedbook}`, p[0])
                  .then((response) => {
                    console.log(response)
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }
            }).catch(error => {
              console.log(error)
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }

      dispatch({ type: 'step5' })
      history.push('/')
    }
  }

  const cancel = () => {
    dispatch({ type: 'step5' })
    history.push('/')
  }

  const playbookselected = (book) => {
    console.log(book._id)
    setSelectedBook(book._id)
    const b = document.getElementsByClassName('main2subssave')
    for (let i = 0; i < b.length; i++) {
      b[i].style.border = '2px solid'
    }
    document.getElementById(book._id).style.borderColor = 'rgb(72, 250, 102)'
  }
  return (
      <PlaybookContext.Consumer>
        {(value) => (

            <div>

              <div className="popup2">
                <div className="popbody">
                  <div data-aos="zoom-in" className="pop">
                    <div className="pophead">
                      <Icon icon="bx:bxs-edit"/>
                      <p>Make A Play</p>
                    </div>
                    <div className="mainarea">
                      <div className="withplaybook">
                        <div data-aos="fade" className="mainselection">
                          <div className='midsection'>
                            <div className="headplay">
                              <p>Play Name -</p>
                              {/* <h2><span>{state.play_value}: </span> {state.formation_value}</h2> */}
                              <h2>{state.name}</h2>
                            </div>
                            {!state.editing
                              ? (
                                    <div className='showplaybooklist'>
                                      <h1>Select The Play Book -</h1>
                                      {playbookstate
                                        ? (
                                              <div>
                                                {playbookstate.map((playbookstate) => (
                                                    <div data-aos="zoom-in-up"
                                                         className="main2subssave"
                                                         id={playbookstate._id} onClick={() => {
                                                           playbookselected(playbookstate)
                                                         }}>
                                                      <Icon icon="fe:app-menu"/>

                                                      <Icon
                                                          icon="ic:baseline-drive-folder-upload"
                                                          id="fileicon"/>
                                                      <p className="bold"
                                                         style={{ fontSize: '15px' }}>{playbookstate.playbookname}</p>

                                                      <p className="bold"
                                                         style={{ fontSize: '12px' }}>{playbookstate._id}</p>
                                                    </div>
                                                ))}</div>
                                          )
                                        : (<h3 data-aos="zoom-in-up">no playbook to
                                            select</h3>)}

                                    </div>
                                )
                              : ''}

                          </div>

                        </div>

                      </div>

                    </div>
                    <div className="popbuttons">
                      <button style={{ backgroundColor: 'gray' }} onClick={cancel}>Cancel</button>
                      {state.editing
                        ? (
                              <button style={{ backgroundColor: 'rgb(82, 143, 179)', width: '25%' }}
                                      onClick={save}
                              >Save and Changes

                              </button>
                          )
                        : (
                              <button style={{ backgroundColor: 'rgb(82, 143, 179)', width: '25%' }}
                                      onClick={save}
                              >Save and Make Play

                              </button>
                          )}

                    </div>
                  </div>

                </div>
              </div>

            </div>
        )}</PlaybookContext.Consumer>

  )
}

export default SavePlay
