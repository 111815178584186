import React from 'react'
import './makeplaybook.css'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions, DialogContent } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

function DeletePlayDialog (props) {
  const done = () => {
    props?.deletePlay(props.play)
    props?.setTrigger(false)
  }
  const cancel = () => {
    props?.setTrigger(false)
  }

  return (
      <Dialog open={props.trigger} onClose={cancel}>
        <DialogTitle>Delete Play</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Are you sure you would like to delete this play? '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>No</Button>
          <Button onClick={done}>Yes</Button>
        </DialogActions>
      </Dialog>
  )
}

DeletePlayDialog.propTypes = {
  play: PropTypes.any.isRequired,
  deletePlay: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired
}
export default DeletePlayDialog
