import React, { useState } from 'react'
import 'aos/dist/aos.css'
import Player from '../player/Player'
import PlayerOpposition from '../player/PlayerOpposition'
import './position.css'
import DEFENSE_FORMATION_DATA from '../display-formation-defense'
import FORMATION_DATA from '../display-formation-offense'
import PlayerJobs from '../player/PlayerJobs'
import { Icon } from '@iconify/react'
import PropTypes from 'prop-types'

const BACKGROUND_FIELDS = {
  'Mid-Field': `${window.location.origin}/assets/field.jpg`,
  'Red Zone': `${window.location.origin}/assets/redzone.jpg`,
  'Backed-up': `${window.location.origin}/assets/backed-up.jpg`
}

const Position = ({ selection, players, selective, background, playValue, formation, flipped }) => {
  const [opposition, setOpposition] = useState(false)
  const [oppositionSelection, setOppositionSelection] = useState(false)
  const [oppositionFormation, setOppositionFormation] = useState('')
  const [playersOpposition, setPlayersOpposition] = useState()

  const PlayerJobList = players.map((player) => (<>
    <PlayerJobs
        // style={{position:"absolute"}}
        key={player.id}
        player={player}
        position={player.position}
        selection={selection}
        formation={formation}
        flipped={flipped}
    />
    {console.log('pl', player)}
  </>))
  const PlayerList = players.map((player) => (<>
    <Player
        key={player.id}
        player={player}
        position={player.position}
        selection={selection}
        formation={formation}
        flipped={flipped}
    />
    {/* {console.log('pl', player)} */}
  </>))
  const PlayerListOpposition = playersOpposition?.map((player2) => (<>
    <PlayerOpposition
        key={player2.id}
        player={player2}
        position={player2.position}
        flipped={flipped}
        formation={formation}
    />
    {/* {console.log('pl', player)} */}
  </>))
  const setToggle = () => {
    if (oppositionFormation.length === 0) {
      if (formation.formation_type !== 'defense_formation') {
        setOppositionFormation(DEFENSE_FORMATION_DATA)
        console.log('one', formation.formation_type)
      } else {
        setOppositionFormation(FORMATION_DATA)
        console.log('teo', formation.formation_type)
      }
    }
    setOpposition(!opposition)
  }
  const Oppositionplayer = (p) => {
    if (p === 'off') {
      setOppositionSelection(false)
      setOpposition(false)
    } else {
      const op = oppositionFormation.formations.filter(val => val.id === p)[0]
      setPlayersOpposition(op.players)
      console.log(op)
      setOppositionSelection(true)
      setOpposition(false)
    }
  }

  return (
      <div className="position-side">
        <div data-aos="fade" className="position-side-header">
          <h3 data-aos="zoom-in">
            {playValue} PLAY: <span>CHOOSE {formation.formation_title} PLAYER</span>
          </h3>
          <div className="player-list">
            {players.map((player) => {
              // {console.log( 'player-jobs',player.displayValue)}
              if (player.displayValue === 'QB') {
                if (player.jobs) {
                  if (player.jobs.length === 6) {
                    return (
                        <div
                            data-aos="fade-up"
                            className="player-list-selected"
                            onClick={() => selection(player.id)}
                            onDoubleClick={() => selection('')}
                        >
                          <div className="player-list-selected-item">
                            <div unselectable="on" onselectstart="return false"><p unselectable="on"
                                                                                   onselectstart="return false">
                              {player.displayValue}
                            </p></div>
                            <img src={'/assets/SelectedCircle.png'}/>
                          </div>
                        </div>
                    )
                  }
                }
              } else {
                if (player.jobs) {
                  if (player.jobs.length === 4) {
                    return (
                        <div

                            className="player-list-selected"
                            onClick={() => selection(player.id)}
                            onDoubleClick={() => selection('')}
                        >
                          <div className="player-list-selected-item">
                            <div unselectable="on" onselectstart="return false"><p unselectable="on"
                                                                                   onselectstart="return false">
                              {player.displayValue}
                            </p></div>
                            <img src={'/assets/SelectedCircle.png'}/>
                          </div>
                        </div>
                    )
                  }
                }
              }

              if (player.id === selective) {
                return (<div style={{ display: 'flex', flexDirection: 'column' }}
                             onDoubleClick={() => selection('')}>
                  {console.log(player)}
                  <div className="players-list-item players-selective-item">
                    <div>

                      <p unselectable="on" onselectstart="return false">
                        {player.displayValue}
                      </p></div>

                  </div>
                </div>)
              }
              if (player.jobs) {
                if (player.jobs.length > 0) {
                  return (
                      <div
                          className="player-list-selected"
                          onClick={() => selection(player.id)}
                          onDoubleClick={() => selection('')}
                      >
                        <div className="player-list-selected-item">
                          <div unselectable="on" onselectstart="return false">
                            <p unselectable="on" onselectstart="return false">
                              {player.displayValue}
                            </p>
                          </div>
                          <img src={'/assets/SelectedCircle.png'}/>
                        </div>
                      </div>
                  )
                }
              }

              return (
                  <div
                      data-aos="fade-right"
                      className="players-list-item"
                      onClick={() => selection(player.id)}
                      onDoubleClick={() => selection('')}
                  >
                    <div unselectable="on" onselectstart="return false">
                      <p unselectable="on" onselectstart="return false">
                        {player.displayValue}
                      </p>
                    </div>
                  </div>
              )
            })}
          </div>
        </div>
        <div className="position" style={{ backgroundImage: `url(${BACKGROUND_FIELDS[background]})` }}>

          <div className='opposition_box'>

            <p>OPPOSITION:</p>
            <div className="opposition_toggle">
              {/* <Switch  onChange={()=>setToggle()} checked={opposition} /> */}
              <Icon onClick={() => {
                setToggle()
              }} rotate={opposition ? (2) : (0)} className='updown' icon="bi:arrow-up-circle"/>
              {opposition
                ? (<>
                    <div>
                      <select className='list'
                              onChange={(e) => {
                                Oppositionplayer(e.target.value)
                              }}
                              multiple>
                        <option value='off'>HIDE</option>
                        {oppositionFormation?.formations.map(val => (
                                <option value={val.id}>{val.value}</option>
                        )
                        )}

                      </select>
                    </div>
                  </>)
                : ''}

            </div>
          </div>
          <div style={{ zIndex: '1' }}>
            {oppositionSelection && playersOpposition ? (<>{PlayerListOpposition}</>) : ''}
          </div>
          {formation.formation_type === 'offense_formation' ? <hr className="center-line"/> : <div/>}
          {PlayerJobList}
          {PlayerList}

          <div className="nextButton4">

          </div>
        </div>
      </div>
  )
}

Position.propTypes = {
  selection: PropTypes.any.isRequired,
  players: PropTypes.any.isRequired,
  selective: PropTypes.any.isRequired,
  background: PropTypes.any.isRequired,
  playValue: PropTypes.any.isRequired,
  formation: PropTypes.any.isRequired,
  flipped: PropTypes.any.isRequired
}

export default Position
