import Playbooks from './components/Playbooks'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import SelectBackground from './components/SelectBackground'
import SelectPlayType from './components/SelectPlayType'
import SelectOffenseFormation from './components/SelectOffenseFormation'
import ViewPage from './components/ViewPage'
import PlaybookContext from './components/PlaybookContextProvider'
import AssignJobs from './components/AssignJobs'
import SavePlay from './components/SavePlay'
import SelectDefenseFormation from './components/SelectDefenseFormation'
import SelectSpecialFormation from './components/SelectSpecialFormation'
import Plays from './components/Plays'
import Home from './components/Home'

function App () {
  React.useContext(PlaybookContext)

  const passToMain = () => {
    return (
            <div>
                <Redirect to='/'></Redirect>
            </div>
    )
  }

  return (
        <div className="App">
            <Switch>
                <Route path="/" component={Home} exact/>
                <Route path="/step1" component={SelectBackground}/>
                <Route path="/step2" component={SelectPlayType}/>
                <Route path="/step3/offense_formation" component={SelectOffenseFormation}/>
                <Route path="/step3/defense_formation" component={SelectDefenseFormation}/>
                <Route path="/step3/special_formation" component={SelectSpecialFormation}/>
                <Route path="/step4" component={AssignJobs}/>
                <Route path="/allplays" component={Plays}/>
                <Route path="/view" component={ViewPage}/>
                <Route path="/saveplay" component={SavePlay}/>
                <Route component={passToMain}/>
            </Switch>
        </div>
  )
}

export default App
