import React, { useEffect } from 'react'
import './step2.css'
import 'aos/dist/aos.css'

import { PlaybookContext } from './PlaybookContextProvider'

function SelectPlayType (props) {
  useEffect(() => {
    const playType = props?.playType
    if (playType) {
      const elem = document.getElementById(playType.value)
      if (elem) {
        elem.style.color = '#c1b638'
        elem.style.backgroundColor = 'rgb(7, 41, 95)'
      }
    }
  })

  const handlePlaystate2 = (elem, n) => {
    const chils = (document.getElementsByClassName('play_values'))
    for (let i = 0; i < chils.length; i++) {
      console.log(chils[i])
      chils[i].style.color = 'rgb(224, 222, 222)'
      chils[i].style.backgroundColor = null
    }
    elem.style.color = '#c1b638'
    elem.style.backgroundColor = 'rgb(7, 41, 95)'
    let selectedType = ''
    switch (n) {
      case '1':
        selectedType = 'offense_play'
        break
      case '2':
        selectedType = 'defense_play'
        break
      case '3':
        selectedType = 'special_team_play'
        break
      case '4':
        selectedType = 'special_team_play'
        break
    }

    const playType = { type: selectedType, value: elem.innerHTML }
    if (props.setPlayType) {
      props.setPlayType(playType)
    }

    if (props.completeStep) {
      props.completeStep(true)
    }

    if (props.play) {
      props.play.play_type = playType.type
      props.play.play_value = playType.value
    }
    console.log(playType)
  }

  return (
      <PlaybookContext.Consumer>
        {(value) => (

            <div className="popup2">
              <div className="popbody">
                <div className="pop">
                  <div className="backgroundtitle">Select Play Type</div>
                  <div className="popselection2">
                    <div className="popcol1" id='1'>
                      <h3>OFFENSE PLAY</h3>
                      <p id="RUN" className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '1')}>RUN</p>
                      <p id='PASS' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '1')}>PASS</p>
                      <p id='OPTION' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '1')}>OPTION</p>

                    </div>
                    <div className="popcol2" id='2'>
                      <h3>DEFENSE PLAY</h3>
                      <p id='RUN COVERAGE' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '2')}>RUN COVERAGE</p>
                      <p id='PASS COVERAGE' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '2')}>PASS COVERAGE</p>
                      <p id='OPTION COVERAGE' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '2')}>OPTION COVERAGE</p>
                    </div>
                    <div className="popcol3" id='3'>
                      <h3>SPECIAL TEAMS PLAY</h3>
                      <p id='PUNT' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>PUNT</p>
                      <p id='PUNT RETURN' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>PUNT RETURN</p>
                      <p id='PUNT BLOCK' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>PUNT BLOCK</p>
                      <p id='KICK RETURN' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>KICK RETURN</p>
                      <p id='KICK OFF' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>KICK OFF</p>
                      <p id='ONSIDE KICK' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>ONSIDE KICK</p>
                      <p id='FIELD GOAL/PAT' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>FIELD GOAL/PAT</p>
                      <p id='FIELD GOAL BLOCK' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '3')}>FIELD GOAL BLOCK</p>

                    </div>
                    <div className='popcol3' id='4'>
                      <h3>CLOCK MANAGEMENT</h3>
                      <p id='VICTORY KNEEL' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '4')}>VICTORY KNEEL</p>
                      <p id='SPIKE BALL' className='play_values'
                         onClick={(e) => handlePlaystate2(e.target, '4')}>SPIKE BALL</p>
                    </div>

                  </div>
                </div>

              </div>
            </div>

        )}</PlaybookContext.Consumer>
  )
}

export default SelectPlayType
