import React, { useEffect, useState } from 'react'
import './makeplaybook.css'
import { DialogActions, DialogContent, TextField } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Dialog  from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

function RenamePlaybookDialog (props) {
  const [playbookName, setPlaybookName] = useState(props?.playbook?.playbookname ?? '')

  useEffect(() => {
    if (props.playbook) {
      props.playbook.playbookname = playbookName
    }
  }, [playbookName])

  const cancel = () => {
    props.setTrigger(false)
  }

  const save = () => {
    props.playbook.playbookname = playbookName
    props.renamePlaybook(props.playbook)
    props.setTrigger(false)
  }

  const handleClose = () => {
    props.setTrigger(false)
  }

  const handleNameChange = (e) => {
    setPlaybookName(e.target.value)
  }

  return (
      <Dialog open={props.trigger} onClose={handleClose}>
        <DialogTitle>Rename Playbook</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a new name for this playbook.
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="string"
              fullWidth
              variant="standard"
              onChange={handleNameChange}
              sx={{ mb: 3, width: 500 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
  )
}

RenamePlaybookDialog.propTypes = {
  playbook: PropTypes.any.isRequired,
  renamePlaybook: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired
}

export default RenamePlaybookDialog
