import React, { useEffect, useState } from 'react'
import './makeplaybook.css'
import { DialogActions, DialogContent, TextField } from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

function UploadPlayArtDialog (props) {
  const [fileName, setFileName] = useState('')
  const [selectedFile, setSelectedFile] = useState('')

  const handleClose = () => {
    props.setTrigger(false)
    setFileName('')
  }

  useEffect(() => {

  })

  const handleFile = (e) => {
    const file = e.target.files[0]
    setFileName(file.name)
    setSelectedFile(file)
  }

  const save = () => {
    props.uploadPlayArt(props.play, selectedFile)
    props.setTrigger(false)
    setFileName('')
  }

  return (
      <Dialog open={props.trigger} onClose={handleClose}>
        <DialogTitle>Upload Play Art</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a file to use as the play art image for this play.
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="string"
              fullWidth
              variant="standard"
              value={fileName}
              sx={{ mb: 3, width: 500 }}
              InputProps={{
                readOnly: true
              }}
          />
          <Button variant="contained" component="label">
            Select File
            <input hidden accept="image/*" multiple type="file" onChange={handleFile} />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save}>Upload</Button>
        </DialogActions>
      </Dialog>
  )
}

UploadPlayArtDialog.propTypes = {
  play: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
  setTrigger: PropTypes.any.isRequired
}

export default UploadPlayArtDialog
